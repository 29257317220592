

























































































































.priority-item {
  min-height: auto;
  cursor: pointer;
  display: grid;

  grid-template-columns: 30px auto 110px;

  .selected-icon {
    grid-column: 1/2;
    grid-row: 1/1;
    display: flex;
    align-items: center;
  }

  .priority-time-and-name {
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: normal;
  }

  .priority-latest-order-time {
    grid-column: 2/3;
    grid-row: 2/3;
    line-height: normal;
  }

  .priority-price {
    grid-column: 3/4;
    grid-row: 1/2;
    font-size: 0.9em;
    line-height: normal;
  }

  @media(max-width: 992px) {
  }
}
