.footer-container {
  padding: 0;
  position: inherit;

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 0;
    }
  }

  .container {
    padding: 3em;
  }
}

// stylelint-disable selector-no-qualifying-type
img.payment-icon {
  height: 35px;
  margin: 10px;
}
// stylelint-enable selector-no-qualifying-type
