














































































































































































































































































































.cart-list-item {
  display: grid;
  grid-template-columns: 2.5rem 185px 1fr 175px 100px 100px;
  grid-template-rows: 2.5rem 1fr;
  border-bottom: 1px dotted #ddd;

  &:hover {
    background-color: rgb(250,250,250);
  }

  &.selected {
    background-color: rgba(186, 223, 255, 0.25);

    &:hover {
      background-color: rgba(186, 223, 255, 0.4);
    }
  }
}

.cart-list-item-title-size {
  grid-row: 1/3;
  grid-column: 3/4;
  cursor: pointer;
}

.cart-list-item-details {
  grid-column: 3/5;
  grid-row: 2/3;
}

.cart-list-item-products {
  grid-row: 2/3;
  grid-column: 1/3;
}

.cart-list-item-select {
  grid-row: 1/2;
  grid-column: 1/2;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
}

.cart-list-item-image {
  cursor: pointer;
  grid-row: 2/3;
  grid-column: 1/3;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  justify-items: center;

  img {
    display: block;
    max-width: 100%;
    max-height: 200px;
    width: auto;
  }
}

.cart-list-item-quantity {
  grid-column: 4/5;
  grid-row: 1/2;
  display: flex;
  align-items: center;
}

.cart-list-item-price-container {
  grid-column: 5/6;
  grid-row: 1/2;
  display: flex;
  justify-content: space-between;
  // justify-content: center;
  align-items: center;

  .cart-list-item-price {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }
}


.cart-list-item-subtotal {
  grid-column: 6/7;
  grid-row: 1/2;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  .cart-list-item-subtotal-total-price {
    font-size: 0.8rem;
  }
}

.cart-list-item-description {
  grid-column: 3/4;
  display: flex;
  align-items: center;
}

.cart-list-total {
  padding-top: 0;
}

.cart-list-total .cart-list-item-subtotal {
  border-top: 2px solid #000;
}

.cart-list-item-standard-actions-container,
.cart-list-item-special-actions-container {
  grid-column: 5/7;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cart-list-item-standard-actions-container {
  grid-row: 2;
}

.cart-list-item-special-actions-container {
  grid-row: 3;
}

.cart-list-item-edit,
.cart-list-item-delete {
  min-width: auto;
}

@media (min-width: 1200px) {
  .cart-list-item-edit,
  .cart-list-item-delete {
    grid-column: 5/7;
    grid-row: 2;
    align-self: flex-start;
    justify-self: flex-end;
  }
}

@media (max-width: 1200px) {
  .cart-list-item {
    grid-template-columns: 2.5rem 185px 1fr 100px 100px;
  }

  .cart-list-item-quantity {
    grid-row: 3/4;
    grid-column: 3/4;
    justify-content: flex-end;
  }

  .cart-list-item-price {
    grid-row: 3/4;
    grid-column: 4/5;
  }

  .cart-list-item-subtotal {
    grid-row: 3/4;
    grid-column: 5/6;
  }

  .cart-list-item-actions {
    grid-row: 3/4;
    grid-column: 6/7;
  }

  .cart-list-item-image {
    grid-row: 2/4;
  }

  .cart-list-item-definition {
    grid-column: 3/7;
    grid-row: 2;
  }

  .cart-list-item-action {
    grid-row: 1;
    grid-column: 4/7;
  }

  .cart-list-totals .cart-list-item {
    margin-top: 0;
    .cart-list-item-subtotal {
      grid-row: 1/2;
    }
  }
}

@media (max-width: 992px) {
  .cart-list-item {
    grid-template-columns: 2.5rem 100px 1fr 75px 75px;
  }

  .cart-list-item-actions {
    grid-row: 1/2;
    grid-column: 5/6;
  }
}

@media (max-width: 768px) {
  .cart-list-item {
    grid-template-columns: 2.5rem 100px 1fr 75px 75px;
  }

  .cart-list-item-actions {
    grid-row: 3/4;
    grid-column: 6/7;
  }

  .cart-list-item-definition .btn {
    display: none;
  }
}

@media (max-width: 576px) {
  .cart-list-item {
    grid-template-columns: 2.5rem 75px 1fr 75px 75px;
  }

  .cart-list-item-actions {
    grid-row: 1/2;
    grid-column: 5/6;
  }

  .cart-list-item-quantity {
    grid-column: 3/4;
  }

  .cart-list-item-image {
    grid-row: 2/3;
  }

  .cart-list-item-select {
    display: none;
  }
}

@media (max-width: 500px) {
  .cart-list-item {
    grid-template-columns: 2.5rem 75px 40px 1fr 75px;
  }

  .cart-list-item-definition {
    grid-column: 3/6;
  }

  .cart-list-item-quantity {
    grid-column: 1/4;
    justify-items: flex-start;
    justify-content: flex-start;
    padding-left: 15px;
  }

  .cart-list-item-image {
    grid-row: 2/3;
  }

  .cart-list-item-select {
    display: none;
  }

  .cart-list-totals .cart-list-item {
    margin-top: 0;

    .cart-list-item-description {
      grid-column: 1/5;
      padding-left: 10px;
    }

    .cart-list-item-subtotal {
      grid-row: 1/2;
    }
  }
}

@media (max-width: 350px) {
  .cart-list-item {
    grid-template-columns: 160px 1fr;
    grid-template-rows: 1.2rem 1fr 1fr;
  }

  .cart-list-item-definition {
    grid-column: 1/3;
    grid-row: 3/4
  }

  .cart-list-item-quantity {
    grid-column: 1/2;
    grid-row: 4/5;
    justify-items: flex-start;
    justify-content: flex-start;
    padding-left: 15px;
  }

  .cart-list-item-price {
    grid-column: 2/3;
    grid-row: 4/5;
  }

  .cart-list-item-subtotal {
    grid-column: 2/3;
    grid-row: 5/6;
  }

  .cart-list-item-image {
    grid-column: 1/3;
    grid-row: 2/3;
  }

  .cart-list-item-select {
    display: none;
  }

  .cart-list-item-action.cart-list-item-delete {
    grid-column: 1/3;
    grid-row: 4/5;
  }

  .cart-list-item-select-material,
  .cart-list-item-approve,
  .cart-list-item-edit {
    grid-column: 1/3;
    grid-row: 5/6;
  }

  .cart-list-totals .cart-list-item {
    margin-top: 0;

    &.cart-list-total .cart-list-item-subtotal {
      border-top: none;
    }

    &.cart-list-total {
      border-top: 2px solid #000;
    }

    .cart-list-item-description {
      grid-column: 1/3;
      grid-row: 1/2;
      padding-left: 10px;
    }

    .cart-list-item-subtotal {
      grid-column: 1/3;
      grid-row: 2/3;
    }
  }
}
