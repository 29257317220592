

































































































































































.btn-container-center {
  display: flex;
  flex-direction: row;
  align-content: center;

  h1 {
    padding-top: 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    vertical-align: middle;
  }
}

.btn-container {
  margin-top: 2px;
  width: 210px
}
.navigation {
  margin-top: 75px;
  top: 75px;
  position: sticky;
  z-index: 20;
  background-color: #ffffff;
  padding-right: calc(10px + (100% - 100vw));
}
.navigation .progress-bar-indeterminate.bg-snijlab {
    border-bottom-color: #fff5a3;
}
.progress {
    margin-bottom: -5px;
}
.navigation .nav {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

// .navigation .nav .btn-container .btn
// {
//   font-size: 0.875rem;
//   padding: 0.5rem 0.75rem;
// }

.navigation .nav .nav-link {
  cursor: pointer;
  text-transform: none;
}

.btn-container {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 0.2rem;
}
.btn-container.btn-container-right {
  justify-content: flex-end;
}
.navigation .btn {
  margin: 5pt 0;
}

.nav-item {
  display: flex;
}

@media (max-width: 1092px) {
  .navigation .btn {
    margin: 3pt 0;
  }
}

@media (max-width: 1084px) {

  .navigation .nav .btn-container .btn {
    font-size: 0.75rem;
    padding: 0.5rem;
  }

  .btn-container {
    width: 185px;

  }

  .navigation .nav .nav-link {
    min-height: 2.25rem;
    padding: 0.75rem;
    font-size: 0.75rem;
  }

  .btn-container.btn-container-right .btn, .btn-container.btn-container-left .btn {
    right: inherit;
    position: relative;
  }

  .btn-container {
    margin-top: 2px;
    /*width: 100px*/
  }

  .btn-container-center h1 {
    padding-top: 0.70rem;
    font-size: 0.75rem;
    font-weight: 400;
  }

  .btn-container-left {
    justify-content: flex-start;
  }
}

@media (max-width: 878px) {
  .navigation .nav .btn-container {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .navigation .nav {
    padding-left: 0;
    padding-right: 0;
  }

  .nav-item > .nav-link {
    display: none;
  }

  .nav-item > .nav-link.active {
    display: block;
  }
}

@media (max-width: 576px) {

  .btn-container {
    width: auto;
  }

  .num {
    display: none;
  }

  .btn-container .btn i.material-icons {
    display: none;
  }

  .nav.nav-tabs {
    justify-content: space-around;

    .btn-container-right-spacer {
      display: none;
    }
    .btn-container-center {
      align-self: flex-start;
      // margin-left: 0.5rem;
    }
    .btn-container-right {
      align-self: flex-end;
      margin: 0;
    }

    .btn-container.btn-cart {
      margin: 0;
    }
  }
}
