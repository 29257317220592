



























































































































































































.knowledgebase-page {
  margin-top: 75px;

  a:link {
    color: transparent;
  }

  a:link, a:hover, a:active, a:visited {
    color: #278fce;
  }

  h1, h2, h3 {
    a:link, a:active, a:visited {
      color: transparent
    }
    a:hover {
      color: #278fce;
    }
  }

  h1 {
    font-size: 1.8em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.4em;
  }

  th, td {
    padding: 0.7em;
  }

  .center-col {

    img,
    iframe {
      width: 100%;
    }
  }

  .page-menu-left,
  .page-menu-right {
    z-index: 1;
    .sticky-top {
      top: 95px;
    }

    h4 {
      font-size: 1.1em;
      line-height: 150%;
      border-left: 6px solid #fff;
      padding-left: 10px;

      &.currentPage {
        a:link, a:hover, a:visited, a:active {
          color: #424242;
          text-decoration: none;
        }
        font-weight: bold;
        border-color: #424242;
      }
    }
  }
  .page-menu-right {
    min-height: 80vh;
    background-color: #f5f5f5;
    .navbar {
      height: auto;
      .nav-link {
        padding: 0 0 0 10px;
        margin-bottom: 6px;
        border-left: 6px solid #f5f5f5;

        &.active {
          font-weight: bold;
          border-color: #424242;
          color: #424242;
        }
        &:hover {
          text-decoration: underline;
        }

      }
    }
  }

}
// .kb-page {
//   a:link, a:hover, a:active, a:visited {
//     color: #278fce;
//   }

//   .sidebar-menu-left, .sidebar-menu-right {
//     padding-top: 30px;
//     color: #fff;
//   }

//   h4 {
//     font-size: .8rem;
//     border-left: 6px solid #fff;
//     padding-left: 10px;
//     font-weight: normal;

//     &.currentPage {
//       font-weight: bold;
//       border-color: #424242;;
//       a {
//         color: #424242;
//       }
//     }
//   }

//   .center-content {
//     margin-top: 75px;
//     flex: 1 1;

//     .center-content-wrapper {
//       max-width: 900px;
//       padding-bottom: 200px;
//       img {
//         width: 100%;
//       }
//     }

//     h1 {
//       color: red;
//       font-size: 1.8em;
//     }

//     h2 {
//       color: blue;
//       font-size: 1.3rem;
//     }

//     h3 {
//       color: orange;
//     }

//     th, td {
//       color: green;
//       padding: 0.8rem;
//     }
//   }

//   .sidebar-menu-right {
//     background-color: #f5f5f5;
//     flex: 0 0 300px;

//     .navbar {
//       height: auto;
//     }

//     .nav-link {
//       padding: 0 0 0 10px;
//       margin-bottom: 6px;
//       border-left: 6px solid #f5f5f5;

//       &.active {
//         font-weight: bold;
//         border-color: #424242;
//         color: #424242;
//       }
//     }
//   }
// }
