<template>
  <span @click="click" class="chip chip-toggle small" :class="{ 'chip-checked': value }" href="#">
    <i class="material-icons chip-toggle-icon" v-if="icon !== null">{{ icon }}</i>
    <slot>
    </slot>
  </span>
</template>

<script>
export default {
  name: 'ChipToggle',
  props: {
    icon: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click(val) {
      this.$emit('click', val);
    },
  },
};
</script>

<style>
  .chip {
    margin: 2px;
    font-size: 0.7rem;
    background-color: #fff;
    border: 1px solid #ccc;
  }

  .chip-toggle-icon {
    display: inline-flex;
    width: 1.5rem;
  }

  .chip-checked {
    background-color: #f5f5f5;
  }

  .chip-toggle {
    cursor: pointer;
  }

  .chip-toggle {
    cursor: pointer;
  }

  .chip-toggle:hover {
    background-color: #eee;
  }
</style>
