










































































































































































































































.payment-view {
  margin: auto;
  // max-width: 1200px;
  display: flex;
  flex-wrap: wrap;

  .half-width {
    width: 50%;
  }
  .half-width:nth-child(even) {
    margin-top: 1rem;
  }

  .summary-card {
    width: auto;

    table {
      width: 100%;
    }
    tr {
      border-bottom: 1px solid rgb(230, 230, 230);
    }
    td {
      padding-top: .1rem;
      padding-bottom: .1rem;
    }
    .total-row {
      border-bottom: none;
      td {
        padding-top: 0.2rem;
      }
    }
  }

}

@media (min-width: 768px) {
  .half-width {
    width: 50%;
    margin-top: 1rem;
  }
  .half-width:nth-child(even) {
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
  .half-width:nth-child(odd) {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
}

