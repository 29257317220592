
























































































































































































































































































































































.material-grid {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

.main-container .d-flex {
  flex-direction: row;
}

.sidebar-filters {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  padding-right: 0;
  padding-left: 14px;

  .material-filter-title {
    width: 100%;
  }

  .material-filter-title,
  .search-filter {
    margin-top: 1rem;
  }
}

.noUi-connect {
  background-color: #ffe400;
}

.noUi-horizontal .noUi-tooltip {
  bottom: -120%;
}

.custom-control {
  padding-left: 1.5rem;
}

.thickness-filter {
  padding-right: 20px;
  padding-bottom: 30px;

}

#thickness-slider {
  margin-left: 20px;
}

.color-filter {
  min-width: 170px;
  max-width: 170px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .color-container {
    padding: 0;
    margin: 4px;
    position: relative;
    display: block;
    height: 30px;
    width: 30px;
    box-shadow: 0 0 12px 0 #e3e3e3;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 11px;
      top: 5px;
      width: 8px;
      height: 15px;
      border: solid #000;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);

    }

    .checkmark.inversed:after {
      border-color: #fff;
    }
  }
}

.no-material-found {
  margin-top: 20px;
  padding-left: 20px;
  font-size: 1rem;
}

@media (max-width: 576px) {

  .main-container .d-flex {
    flex-direction: column;
  }

  .sidebar-filters {
    width: 100%;
    min-width: 100%;
    max-width: 100%;;

    .material-filter-title {
      margin-top: 0;
      margin-bottom: 0;
    }

    .search-filter {
      margin-top: 0;
      margin-bottom: .5rem;
    }

    .color-filter {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin-bottom: .5rem;
    }

    .transparency-filter {
      margin-bottom: .5rem;

      .custom-control {
        display: inline-block;
      }
      .custom-control:nth-of-type(1) {
        margin-right: 0.6rem;
      }
    }

    .thickness-filter {
      margin-bottom: .5rem;
    }

    .tags-filter {
      .material-filter-title {
        margin-bottom: .5rem;
      }
      margin-bottom: 1rem;
    }
  }
}

