











































































































































































































































































































































































































































.frontpage {
  margin-top: 75px;

  .fix-link::before {
    height: 140px;
  }

  header {
    position: relative;
    background-color: black;
    min-height: calc(100vh - 75px);
    width: 100%;
    overflow: hidden;
  }

  header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  header .video-container {
    position: relative;
    z-index: 2;

    .fix-link::before {
      height: 75px;
    }

    .logo-large {
      filter: drop-shadow( 0px 0px 5px rgb(31, 31, 31) );
      max-width: 600px;
    }

    h1 {
      border: none;
      font-size: 1.8em;
    }
    p {
      font-size: 1.2em;
    }
    .arrow a i {
      color: #fff;
    }
    h1,
    p,
    a i {
      text-shadow: rgb(31, 31, 31) 3px 0 10px;
    }
  }

  header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }

  .ticker-row {
    overflow: hidden;
  }

  // @media (pointer: coarse) and (hover: none) {
  //   header {
  //     background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
  //   }
  //   header video {
  //     display: none;
  //   }
  // }


  .btn {
    z-index: 1; // prevent buttons getting covered by headers' margin-top
  }

  // .min-full-height {
  //   min-height: calc(100vh - 75px);
  // }

  // .favorite-software-container {
  //   display: grid;
  // }
  // .grid {
  //   display: grid;
  // }

  .bottom-btn {
    justify-content: center;
    text-align: center;
    align-self: flex-end;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .bg-primary .bottom-btn{
    border-color: rgba(255, 255, 255, 0.5);
  }

  .bg-dark .bottom-btn,
  .bg-dark-1 .bottom-btn,
  .bg-dark-2 .bottom-btn,
  .bg-dark-3 .bottom-btn,
  .bg-dark-4 .bottom-btn {
    border-color: rgba(255, 255, 255, 0.3);
  }

  ul {
    list-style-type: none;
    display: inline-block;
    text-align: left;

    li {
      font-size: 0.9rem;
      .material-icons {
        margin-right: .5rem;
      }
    }
  }

  // @media (max-width: 500px) {
  //   ul {
  //     padding: 0;

  //     li {
  //       font-size: 0.7rem;
  //     }
  //   }
  // }


  .hreflink {
    display: none;
    &:hover {
      display: block;
    }
  }

  h1 {
    text-align: center;
    justify-self: center;
    font-size: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 1rem;
  }
  // .bg-primary h1 {
  //   border-color: rgba(255, 255, 255, 0.5);
  // }
  .bg-dark h1,
  .bg-dark-1 h1,
  .bg-dark-2 h1,
  .bg-dark-3 h1,
  .bg-dark-4 h1 {
    border-color: rgba(255, 255, 255, 0.3);
  }

  .stepper .stepper-icon {
    background-color: #424242;
  }

  // .intro-container {
  //   min-height: calc(100vh - 75px);
  //   width: 100vw;
  //   overflow: hidden;

  //   h1 {
  //     border: none;
  //   }

  //   .subtitle {
  //     color: #fff;
  //     text-shadow: #000 3px 0 10px;

  //     p {
  //       font-size: 1.1rem;
  //       line-height: normal;
  //     }
  //   }

  //   .video-container {
  //     z-index: -99;
  //     position: absolute;
  //     overflow: hidden;
  //     top: -1px;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;

  //     video {
  //       min-width: 100%;
  //       min-height: 100%;
  //       display: block;
  //       position: absolute;
  //       left: 50%;
  //       top: 50%;
  //       transform: translate(-50%, -50%);
  //       // z-index: 1;
  //     }
  //     // @media screen and (max-aspect-ratio: 1920/1080) {
  //     //   video {
  //     //     height: 100%;
  //     //   }
  //     // }
  //     @media screen and (min-aspect-ratio: 1920/1080) {
  //       video {
  //         width: 100%;
  //       }
  //     }
  //   }

  //   .arrow a i {
  //     color: #fff;
  //     filter: drop-shadow( 0px 0px 5px #000 );
  //   }

  // }

  // .stepper-container {
    // .stepper-icon {
    //   background-color: #424242;
    //   color: #000;
    //   background-color: #fff;
    //   filter: drop-shadow( 0px 0px 5px #000 );
    // }

    // .stepper-vert {
    //   display: none;
    //   // margin: auto;
    // }
    // @media (max-width: 1200px) {
    //   .stepper-horiz {
    //     display: none;
    //   }
    //   .stepper-vert {
    //     display: inline-block;
    //   }
    // }
  // }

  .web-app-container {
    img {
      max-width: 300px;
      max-height: none;
      height: auto;
    }
    p {
      text-align: center;
    }
    ul {
      margin: auto;
    }
  }

  .gemaakt-voor-jou-container {
    img {
      width: 60%;
    }
  }

  .materialen-container {
    text-align: center;

    p {
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: calc(6 * (220px + .5rem));

      img {
        width: 220px;
        height: 220px;
        margin: .5rem;
        max-height: none;
        box-shadow: 0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 3px rgba(0,0,0,.12),0 4px 15px 0 rgba(0,0,0,.2);
      }
      @media (max-width: 520px) {
        a {
          display: none;
        }
        a:nth-child(-n+3) {
          display: inline-block;
        }
      }
      @media (min-width: 521px) and (max-width: 992px) {
        a {
          display: none;
        }
        a:nth-child(-n+5) {
          display: inline-block;
        }
      }
    }
  }

  .prijzen-container {
    input,
    select {
      color: #000;
      // border-co1lor: #fff;
    }

    .prijzen-table-container {
      text-align: center;
      table {
        font-size: 0.8rem;
        text-align: left;
        display: inline-block;
      }
    }

    .input-group-append {
      width: 40px;
      text-align: left;
    }

    .calc-target-container {
      display: grid;
    }
    .calc-target {
      justify-self: flex-end;
      font-size: 1.5rem;
    }
  }
}
