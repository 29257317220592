<template>
  <div>
    <div class="shippingForm card mb-3">
      <div class="card-block-title pl-2 py-1 bg-light">
        <div v-if="invoice_is_delivery_address && !isPickup"
            class="card-title">Aflever- en factuuradres</div>
        <div v-else class="card-title">Factuuradres</div>
      </div>
      <div class="card-body border-top pt-1">

        <form-async-widget-single-checkbox
          :value="is_business"
          :error="is_business_error"
          v-bind="InvoiceForm.is_business"
          :checked-value="true"
          :unchecked-value="false"
          input-name="is_business"
          @update="updateIsBusiness"
        />
        <form-async-widget-input
          v-show="is_business"
          :clear-tainted="!is_business"
          :value="invoice_company"
          :error="invoice_company_error"
          v-bind="InvoiceForm.company"
          type="text"
          input-name="invoice_company"
          size="sm"
          @update="update"
        />
        <form-async-widget-input
          :value="invoice_name"
          :error="invoice_name_error"
          v-bind="InvoiceForm.name"
          type="text"
          input-name="invoice_name"
          size="sm"
          @update="update"
        />
        <form-async-widget-input
          v-show="is_business"
          :clear-tainted="!is_business"
          :value="invoice_email"
          :error="invoice_email_error"
          v-bind="InvoiceForm.email"
          type="text"
          input-name="invoice_email"
          size="sm"
          @update="update"
        />
        <form-widget-custom
          v-bind="InvoiceForm.country"
          :loading="invoiceCountryLoading">
          <country-selector
            :value="invoice_country"
            @update="updateInvoiceCountry"
          />
        </form-widget-custom>
        <form-async-widget-input
          v-show="invoice_is_delivery_address && !isPickup && invoice_country !== 'NL'"
          :clear-tainted="!(invoice_is_delivery_address && !isPickup && invoice_country !== 'NL')"
          :value="delivery_phone_number"
          :error="delivery_phone_number_error"
          v-bind="ShippingForm.phone_number"
          type="text"
          input-name="delivery_phone_number"
          size="sm"
          @update="update"
        />
        <form-async-widget-input
          v-show="isApplicableForICP"
          :value="vat_number"
          :clear-tainted="!isApplicableForICP"
          :error="vat_number_error"
          v-bind="InvoiceForm.vat_number"
          type="text"
          input-name="vat_number"
          size="sm"
          @update="update"
        />
        <div class="shippingForm" v-show="invoice_country === 'NL'">
          <form-async-widget-input
            row-class="input-row-half postcodeRow"
            :delay="200"
            :value="invoice_zip_code"
            :error="invoice_zip_code_error"
            :tainted-value.sync="invoice_zip_codeTainted"
            v-bind="InvoiceForm.zip_code"
            type="text"
            input-name="invoice_zip_code"
            size="sm"
            @update="updateZipInvoice"
          />
          <form-async-widget-input
            row-class="input-row-half"
            :delay="200"
            :value="invoice_address_number"
            :error="invoice_address_number_error"
            :tainted-value.sync="invoice_address_numberTainted"
            v-bind="InvoiceForm.address_number"
            :warning="invoicePostcodeHasError"
            type="text"
            input-name="invoice_address_number"
            size="sm"
            @update="updateZipInvoice"
          />
          <form-async-widget-input
            :value="invoice_address"
            :error="invoice_address_error"
            :tainted-value.sync="invoice_addressTainted"
            v-bind="InvoiceForm.address"
            type="text"
            input-name="invoice_address"
            size="sm"
            @update="updateZipInvoice"
          />
          <form-async-widget-input
            :value="invoice_address_extra"
            :error="invoice_address_extra_error"
            v-bind="ShippingForm.address_extra"
            type="text"
            input-name="invoice_address_extra"
            size="sm"
            @update="update"
          />
          <form-async-widget-input
            :value="invoice_city"
            :error="invoice_city_error"
            :tainted-value.sync="invoice_cityTainted"
            v-bind="InvoiceForm.city"
            type="text"
            input-name="invoice_address"
            size="sm"
            @update="updateZipInvoice"
          />
        </div>
        <div class="shippingForm" v-show="invoice_country !== 'NL'">
          <form-async-widget-input
            :value="invoice_address"
            :error="invoice_address_error"
            v-bind="InvoiceForm.address_non_nl"
            type="text"
            input-name="invoice_address"
            size="sm"
            @update="update"
          />
          <form-async-widget-input
            :value="invoice_address_extra"
            :error="invoice_address_extra_error"
            v-bind="ShippingForm.address_extra"
            type="text"
            input-name="invoice_address_extra"
            size="sm"
            @update="update"
          />
          <form-async-widget-input
            :value="invoice_zip_code"
            :error="invoice_zip_code_error"
            v-bind="InvoiceForm.zip_code"
            type="text"
            input-name="invoice_zip_code"
            size="sm"
            @update="update"
          />
          <form-async-widget-input
            :value="invoice_city"
            :error="invoice_city_error"
            v-bind="InvoiceForm.city"
            type="text"
            input-name="invoice_city"
            size="sm"
            @update="update"
          />
        </div>
        <div class="p-2">* Verplicht</div>
        <form-async-widget-single-checkbox
          v-show="!isPickup"
          :value="invoice_is_delivery_address"
          :error="invoice_is_delivery_address_error"
          v-bind="InvoiceForm.invoice_is_delivery_address"
          input-name="invoice_is_delivery_address"
          label="Levering op ander adres"
          :invert-value="true"
          @update="update"
        />
      </div>
    </div>

    <div v-show="!invoice_is_delivery_address && !isPickup"
      class="shippingForm card mb-3">
      <div class="card-block-title pl-2 py-1 bg-light">
        <div class="card-title">Afleveradres</div>
      </div>
      <div class="card-body">
        <form-async-widget-input
          v-show="is_business"
          :value="delivery_company"
          :error="delivery_company_error"
          v-bind="ShippingForm.company"
          type="text"
          input-name="delivery_company"
          size="sm"
          @update="update"
        />
        <form-async-widget-input
          :value="delivery_name"
          :error="delivery_name_error"
          v-bind="ShippingForm.name"
          type="text"
          input-name="delivery_name"
          size="sm"
          @update="update"
        />
        <form-widget-custom v-bind="ShippingForm.country">
          <country-selector
            :value="delivery_country"
            @update="updateShippingCountry"
            :loading="shippingCountryLoading"
          />
        </form-widget-custom>
        <div class="shippingForm" v-show="delivery_country === 'NL'">
          <form-async-widget-input
            row-class="input-row-half postcodeRow"
            :delay="200"
            :value="delivery_zip_code"
            :error="delivery_zip_code_error"
            :tainted-value.sync="delivery_zip_codeTainted"
            v-bind="ShippingForm.zip_code"
            type="text"
            input-name="delivery_zip_code"
            size="sm"
            @update="updateZipDelivery"
          />
          <form-async-widget-input
            row-class="input-row-half"
            :delay="200"
            :value="delivery_address_number"
            :error="delivery_address_number_error"
            :tainted-value.sync="delivery_address_numberTainted"
            v-bind="ShippingForm.address_number"
            :warning="deliveryPostcodeHasError"
            type="text"
            input-name="delivery_address_number"
            size="sm"
            @update="updateZipDelivery"
          />
          <form-async-widget-input
            :value="delivery_address"
            :error="delivery_address_error"
            :tainted-value.sync="delivery_addressTainted"
            v-bind="ShippingForm.address"
            type="text"
            input-name="delivery_address"
            size="sm"
            @update="updateZipDelivery"
          />
          <form-async-widget-input
            :value="delivery_address_extra"
            :error="delivery_address_extra_error"
            v-bind="ShippingForm.address_extra"
            type="text"
            input-name="delivery_address_extra"
            size="sm"
            @update="update"
          />
          <form-async-widget-input
            :value="delivery_city"
            :error="delivery_city_error"
            :tainted-value.sync="delivery_cityTainted"
            v-bind="ShippingForm.city"
            type="text"
            input-name="delivery_address"
            size="sm"
            @update="updateZipDelivery"
          />
        </div>
        <div class="shippingForm" v-show ="delivery_country !== 'NL'">
          <form-async-widget-input
            :value="delivery_phone_number"
            :error="delivery_phone_number_error"
            v-bind="ShippingForm.phone_number"
            type="text"
            input-name="delivery_phone_number"
            size="sm"
            @update="update"
          />
          <form-async-widget-input
            :value="delivery_address"
            :error="delivery_address_error"
            v-bind="ShippingForm.address_non_nl"
            type="text"
            input-name="delivery_address"
            size="sm"
            @update="update"
          />
          <form-async-widget-input
            :value="delivery_address_extra"
            :error="delivery_address_extra_error"
            v-bind="ShippingForm.address_extra"
            type="text"
            input-name="delivery_address_extra"
            size="sm"
            @update="update"
          />
          <form-async-widget-input
            :value="delivery_zip_code"
            :error="delivery_zip_code_error"
            v-bind="ShippingForm.zip_code"
            type="text"
            input-name="delivery_zip_code"
            size="sm"
            @update="update"
          />
          <form-async-widget-input
            :value="delivery_city"
            :error="delivery_city_error"
            v-bind="ShippingForm.city"
            type="text"
            input-name="delivery_city"
            size="sm"
            @update="update"
          />
        </div>
        <div class="p-2">* Verplicht</div>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue';
import AsyncQueue from 'src/components/tools/AsyncQueue';
import FormAsyncWidgetInput from 'src/components/form-async/FormAsyncWidgetInput';
import FormWidgetInput from 'src/components/form/FormWidgetInput';
import FormAsyncWidgetSingleCheckbox from 'src/components/form-async/FormAsyncWidgetSingleCheckbox';
import FormWidgetCustom from 'src/components/form/FormWidgetCustom';
import CountrySelector from 'src/components/tools/CountrySelector';
import LoadingWidget from 'src/components/tools/LoadingWidget';
import FormWidgetPlaces from 'src/components/form/FormWidgetPlaces';
import FormAsyncWidgetSingleRadio from 'src/components/form-async/FormAsyncWidgetSingleRadio';

const shippingWidgets = {
  name: {
    label: 'Ter attentie van',
    placeholder: 'Voor- en achternaam',
    help: '',
    error: '',
    required: true,
  },
  company: {
    label: 'Bedrijfsnaam *',
    placeholder: '',
    help: '',
    error: '',
    required: false,
  },
  address: {
    label: 'Straat',
    placeholder: '',
    help: '',
    error: '',
    required: true,
  },
  address_non_nl: {
    label: 'Straat en nummer',
    placeholder: '',
    help: '',
    error: '',
    required: true,
  },
  address_extra: {
    label: 'Extra adresregel',
    placeholder: 'unit, gebouw, verdieping (optioneel)',
    help: '',
    error: '',
    required: false,
  },
  address_number: {
    label: 'Huisnummer en toevoeging',
    placeholder: 'bv. 124A',
    help: '',
    error: '',
    required: true,
  },
  zip_code: {
    label: 'Postcode',
    placeholder: '',
    help: '',
    error: '',
    required: true,
    warnings: '',
  },
  city: {
    label: 'Stad',
    placeholder: '',
    help: '',
    error: '',
    required: true,
  },
  country: {
    label: 'Land *',
    placeholder: 'Kies een land…',
    help: '',
    error: '',
    required: true,
  },
  vat_number: {
    label: 'Btw-nummer',
    placeholder: '',
    help: 'de btw wordt automatisch verlegd wanneer je een geldig europees btw-nummer invoert'
        + ' én de lever- en factuuradressen buiten Nederland kiest',
    helpLink: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontenten/belastingdienst'
        + '/business/vat/vat_in_the_netherlands/calculating_vat/reverse-charging_vat',
    error: '',
    required: false,
  },
};
const invoiceWidgets = {
  ...shippingWidgets,
  email: {
    label: 'Email-adres voor facturatie',
    placeholder: '',
    help: 'Een kopie van de factuur wordt naar dit adres verzonden',
    error: '',
    required: false,
  },
  is_business: {
    label: 'Zakelijk',
  },
};

shippingWidgets.phone_number = {
  label: 'Telefoonnummer voor verzending',
  placeholder: '',
  help: 'Je telefoonnummer wordt doorgegeven aan onze pakketdienst',
  error: '',
  required: true,
};

// invoiceWidgets.name.autofocus = true;
const Component = {
  name: 'AddressForm',
  components: {
    FormAsyncWidgetSingleRadio,
    AsyncQueue,
    FormAsyncWidgetInput,
    FormWidgetInput,
    FormAsyncWidgetSingleCheckbox,
    FormWidgetCustom,
    CountrySelector,
    LoadingWidget,
    FormWidgetPlaces,
  },
  props: {
    shippingMethod: {
      type: String,
      required: true,
    },
    storeProperty: {
      type: String,
      required: true,
    },
    storeErrorProperty: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      invoice_zip_codeTainted: null,
      invoice_addressTainted: null,
      invoice_address_numberTainted: null,
      invoice_cityTainted: null,
      delivery_zip_codeTainted: null,
      delivery_addressTainted: null,
      delivery_address_numberTainted: null,
      delivery_cityTainted: null,
      delivery_phone_numberTainted: null,
      invoicePostcodeHasError: null,
      deliveryPostcodeHasError: null,
      invoiceZipUpdating: false,
      deliveryZipUpdating: false,
      scheduleInvoiceUpdate: false,
      shippingCountryLoading: false,
      invoiceCountryLoading: false,
    };
  },
  computed: {
    /**
       * @return {boolean}
       */
    InvoiceNeedsUpdate() {
      return this.getTaintedAddressFields('invoice').value.length > 0;
    },
    ShippingForm() {
      return shippingWidgets;
    },
    InvoiceForm() {
      return invoiceWidgets;
    },
    /**
       * @return {boolean}
       */
    isApplicableForICP() {
      return this.euVatCountries.includes(this.invoice_country) && this.is_business;
    },
    isPickup() {
      return this.shippingMethod === 'PICKUP';
    },
    euVatCountries() {
      return [
        'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'GR', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU',
        'MT', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', 'GB'];
    },
    // invoiceAddressIsDeliveryAddressCheckboxState() {
    //   // invert the checkbox without touching the way its variable works
    //   return !invoice_is_delivery_address;
    // }
  },
  methods: {
    updateInvoiceCountry(value) {
      this.invoiceCountryLoading = true;
      const vm = this;
      this.updateFields({
        field: 'invoice_country',
        value,
      }).finally(() => {
        if (value !== 'NL') {
          this.$store.dispatch('clearInvoiceCountryErrors');
        }
        vm.invoiceCountryLoading = false;
      });
    },
    updateShippingCountry(value) {
      this.shippingCountryLoading = true;
      const vm = this;
      this.updateFields(
        {
          field: 'delivery_country',
          value,
        },
      ).finally(() => {
        if (value !== 'NL') {
          this.$store.dispatch('clearShippingCountryErrors');
          vm.shippingCountryLoading = false;
        }
      });
    },

    updateFields(taintedFields) {
      return new Promise((resolve, reject) => {
        this.$emit('updateFields', taintedFields, resolve, reject);
      });
    },
    update({ promise, value, inputName }) {
      // don't update stuff that's already updated..
      if (this[inputName] === value) {
        promise.resolve();
      }
      this.updateFields({
        field: inputName,
        value,
      }).finally(() => promise.resolve());
    },
    updateIsBusiness({ promise, value, inputName }) {
      if (!value) {
        this.updateFields({
          field: inputName,
          value,
        }).finally(() => {
          promise.resolve();
          this.$store.dispatch('clearCompanyErrors');
        });
      } else {
        this.update({ promise, value, inputName });
      }
    },
    updateZipInvoice({ promise, value, inputName }) {
      this.updateZipLookup(promise, value, 'invoice', inputName);
    },
    updateZipDelivery({ promise, value, inputName }) {
      this.updateZipLookup(promise, value, 'delivery', inputName);
    },
    updateZipLookup(promise, value, type, inputName) {
      if (this[`${type}ZipUpdating`]) {
        promise.resolve();
        return;
      }

      this[`${type}ZipUpdating`] = true;
      const params = {};
      const taintedFields = this.getTaintedAddressFields(type);
      const zipOrNumberTainted = taintedFields.field
        .some((item) => item === `${type}_zip_code` || item === `${type}_address_number`);

      const postcodeNotFound = 'Er is geen adres gevonden met opgegeven postcode en huisnummer '
          + 'of het adres is een postbus. Controleer of dit juist is ingevuld.';
      let hasError = false;

      if (!zipOrNumberTainted) {
        if (taintedFields.value.length === 0) {
          promise.resolve();
          this[`${type}ZipUpdating`] = false;
          return;
        }

        this.updateFields(taintedFields)
          .finally(() => {
            promise.resolve();
            this[`${type}ZipUpdating`] = false;
          });
        return;
      }

      params.postcode = (this[`${type}_zip_codeTainted`] || this[`${type}_zip_code`]).replace(/\s/, '');
      params.huisnummer = (this[`${type}_address_numberTainted`]
       || this[`${type}_address_number`] || '').replace(/^(\d+).*$/, '$1');

      let showPostcodeCheckError = true;
      if ((inputName.match(/zip_code$/) && params.huisnummer === '')
        || (inputName.match(/address_number$/) && params.postcode === '')) {
        showPostcodeCheckError = false;
      }

      axios.get(this.$config.postcodeUrl, { params })
        .then((response) => {
          if (response.data.length === 0 && showPostcodeCheckError) {
            hasError = true;
            promise.resolve();
            return;
          }
          const resultAddress = _get(response, 'data[0].openbareruimte');
          if (this[`${type}_address`] !== resultAddress) {
            this[`${type}_addressTainted`] = resultAddress;
          }
          const resultCity = _get(response, 'data[0].woonplaats');
          if (this[`${type}_city`] !== resultCity) {
            this[`${type}_cityTainted`] = resultCity;
          }
        })
        .catch((response) => {
          if (response.response.status === 400) {
            hasError = true;
          }
        })
        .finally(() => {
          if (hasError) {
            // commit error..
            this[`${type}PostcodeHasError`] = postcodeNotFound;
          } else {
            this[`${type}PostcodeHasError`] = null;
          }
          const fields = this.getTaintedAddressFields(type);
          if (fields.value.length === 0) {
            promise.resolve();
            this[`${type}ZipUpdating`] = false;
          }
          this.updateFields(fields)
            .finally(() => {
              promise.resolve();
              this[`${type}ZipUpdating`] = false;
            });
        });
    },
    getTaintedAddressFields(type) {
      const fields = [];
      const values = [];
      if ((this[`${type}_addressTainted`] || this[`${type}_addressTainted`] === '')
        && this[`${type}_addressTainted`] !== this[`${type}_address`]) {
        values.push(this[`${type}_addressTainted`]);
        fields.push(`${type}_address`);
      }
      if ((this[`${type}_address_numberTainted`] || this[`${type}_address_numberTainted`] === '')
        && this[`${type}_address_numberTainted`] !== this[`${type}_address_number`]) {
        values.push(this[`${type}_address_numberTainted`]);
        fields.push(`${type}_address_number`);
      }
      if ((this[`${type}_cityTainted`] || this[`${type}_cityTainted`] === '')
        && this[`${type}_cityTainted`] !== this[`${type}_city`]) {
        values.push(this[`${type}_cityTainted`]);
        fields.push(`${type}_city`);
      }
      if ((this[`${type}_zip_codeTainted`] || this[`${type}_zip_codeTainted`] === '')
        && this[`${type}_zip_codeTainted`] !== this[`${type}_zip_code`]) {
        values.push(this[`${type}_zip_codeTainted`]);
        fields.push(`${type}_zip_code`);
      }
      return {
        value: values,
        field: fields,
      };
    },
    setInvoiceAddressData(data) {
      this.setAddressData('invoice', data);
    },
    setDeliveryAddressData(data) {
      this.setAddressData('delivery', data);
    },
    setAddressData(type, data) {
      if ('locality' in data) {
        Vue.set(this, `${type}_cityTainted`, data.locality);
      }
      if ('postal_code' in data) {
        Vue.set(this, `${type}_zip_codeTainted`, data.postal_code);
      } else {
        Vue.set(this, `${type}_zip_codeTainted`, '');
      }
      if ('routeParams' in data) {
        Vue.set(this, `${type}_addressTainted`, data.routeParams);
      }
      if ('street_number' in data) {
        Vue.set(this, `${type}_address_numberPublic`, data.street_number);
      } else {
        Vue.set(this, `${type}_address_numberPublic`, '');
      }
      //        if ('name' in data && is_business) {
      //          Vue.set(this.addressData, `${type}_company`, data.name);
      //        }
    },
  },
};

[
  'delivery_phone_number',
  'delivery_address',
  'delivery_address_extra',
  'delivery_address_number',
  'delivery_city',
  'delivery_company',
  'delivery_country',
  'delivery_name',
  'delivery_zip_code',
  'invoice_address',
  'invoice_address_extra',
  'invoice_address_number',
  'invoice_city',
  'invoice_company',
  'invoice_country',
  'invoice_email',
  'invoice_is_delivery_address',
  'invoice_name',
  'invoice_zip_code',
  'vat_number',
  'is_business']
  .forEach((item) => {
    Component.computed[item] = {
      get() {
        return _get(this.$store.state, `${this.storeProperty}.${item}`, '');
      },
    };
    Component.computed[`${item}_error`] = {
      get() {
        return _get(this.$store.state, `${this.storeErrorProperty}.${item}`, '');
      },
    };
  });
export default Component;
</script>
<style>
  .shippingForm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .postcodeRow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
</style>
