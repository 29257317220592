
::-webkit-scrollbar {
  height: 17px;
  width: 17px;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-thumb {
  background: $material-color-grey-400;
  border: 0 none #ffffff;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: $material-color-grey-500;
}

::-webkit-scrollbar-thumb:active {
  background: $material-color-grey-600;
}

::-webkit-scrollbar-track {
  background: $material-color-grey-300;
  border: 0 none #ffffff;
  border-radius: 0;
}

::-webkit-scrollbar-track:hover {
  background: $material-color-grey-400;
}

::-webkit-scrollbar-track:active {
  background: $material-color-grey-500;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
