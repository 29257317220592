







































































































































































































table.order-table {
  border-collapse: collapse;
  td, th {
    /*padding: 0;*/
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: middle;
    color: #000000;
  }

  td:last-child, th:last-child {
    padding-right: 0;
  }

  thead {
    // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    // box-shadow: 0 5px 12px -5px rgba(163, 161, 163, 1);
    // -webkit-box-shadow: 0 5px 12px -5px #a3a1a3;
    box-shadow: 0 5px 12px -5px #a3a1a3;
  }

  thead th {
    font-weight: lighter;
    position: sticky;
    top: 130px;
    z-index: 1;
    background-color: #ffffff;
    border-bottom: 2px solid #cccccc;
    margin-bottom: 2px;
    box-shadow: 0 2px 0 0 #cccccc;
  }

  .no-orders-row {
    background-color: white;
  }
}

@media (min-width: 1200px) {
  table.order-table {
    td, th {
      padding: 0.9rem 1rem;
    }
  }
}

@media (min-width: 1000px) {
  table.order-table {
    width: 100%;
    td, th {
      padding: 0.4rem 0.3rem;
    }
    td:last-child, th:last-child {
      padding-right: 0.5rem;
    }
    td.actions, th.actions {
      min-width: 321px;
      text-align: right;
      vertical-align: middle;

      .btn + .btn {
        margin-top: 0;
        margin-left: 0.5rem;
      }
    }
    td {
      vertical-align: middle;
    }
  }
}

@media (max-width: 1000px) {
  table.order-table {
    td:first-child, th:first-child {
      padding: 0;
    }
    thead th {
      display: none;
    }
    tr {
      display: block;
      padding: 1rem;
    }
    td, th {
      padding: 0;
    }
    td {
      display: block;
      height: auto;
    }
    td::before {
      font-weight: bolder;
      display: inline-block;
      width: 50%;
      content: attr(data-title);
    }

    td.actions, th.actions {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;
      .btn + .btn {
        margin-top: 0.5rem;
        margin-left: 0;
      }
    }
  }
}

.needs-confirmation {
  font-style: italic;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
