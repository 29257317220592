// stylelint-disable declaration-no-important

@mixin bg-variant($class, $color, $color-dark: '') {
  @if ($color-dark !='') {
    a#{$class} {
      @include active-focus-hover {
        background-color: $color-dark;
      }
    }
  }

  #{$class} {
    background-color: $color !important;
    color: color-yiq($color);

    .btn.btn-contrast {
      border-color: color-yiq($color);
      color: color-yiq($color);
    }
  }
}
