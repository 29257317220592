// basic color
$color-primary: #b45bc3 !default;
$color-success: #70c7af !default;
$color-info: #4ba6dd !default;
$color-warning: #ff7f57 !default;
$color-danger: #f05365 !default;
$color-inverse: #424242 !default;
$color-faded: #f5f5f5 !default;
$color-snijlab: #ffe400 !default;
$color-primary-50: rgba($color-primary, 0.6) !default;
$color-info-50: rgba($color-info, 0.6) !default;
// $lightOverlay: rgba(0, 0, 0, 0.04) !default;

.bg-primary-50 {
  background-color: $color-primary-50;
}

.bg-info-50 {
  background-color: $color-info-50;
}
