.card-col.project {
  display: flex;
  position: relative;
}

.card-col.project .card {
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.card-col > a {
  width: 100%;
}

.card-img-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0;
}

.project .card .card-body {
  display: none;
  height: 500px;
  position: absolute;
  top: 0;
  width: 100%;
}

.project:hover .card .card-body {
  background-color: #ffffffcc;
  display: block;
}
