












































































































.qty-widget {
  min-width: 150px;
  display: flex;
  justify-content: right;

  .qty-button {
    min-width: 0;
    width: 10px;
    padding-left: 12px;
    display: inline-block;
  }

  .qty-input-group {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: -15px;
  }
}
