<template>
  <div class="dc-navigator">
    <div class="dc-nav-left">
      <b-button
        v-if="hasPrevious && isCart"
        :to="previousRouteParams"
        size="sm"
        class="btn-light editor-previous-product">
        <i class="material-icons">arrow_back_ios</i> Vorig product
      </b-button>
    </div>
    <div
    v-if="isCart"
    class="dc-nav-center">
      {{ currentIndex + 1 }} / {{ queueLength }} in winkelwagen
    </div>
    <div class="dc-nav-right">
      <b-button
        v-if="hasNext && isCart"
        :to="nextRouteParams"
        size="sm"
        class="editor-next-product"
        :class="{'btn-success': hasValidConfiguration, 'btn-light': !hasValidConfiguration}">
        Volgend product <i class="material-icons">arrow_forward_ios</i>
      </b-button>
      <!-- <b-button
        v-else-if="isCart"
        class="editor-close"
        size="sm"
        :class="{'btn-success': hasValidConfiguration, 'btn-light': !hasValidConfiguration}"
        @click="$emit('vuedals:close')">
        sluiten<i class="material-icons">close</i>
      </b-button> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'dc-editor-navigation',
  props: {
    currentIndex: {
      type: Number,
      required: true,
    },
    isCart: {
      type: Boolean,
      required: true,
    },
    hasValidConfiguration: {
      type: Boolean,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    nextRouteParams: {
      type: Object,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    previousRouteParams: {
      type: Object,
      required: true,
    },
    queueLength: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style>

  .custom-select option {
    font-size: 15px;
    font-weight: 300;
  }

  .dc-nav-center {
    vertical-align: bottom;
    text-align: center;
    display: none;
  }

  .dc-nav-left, .dc-nav-right {
    position: relative;
    display: flex;
  }

  .dc-navigator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dc-nav-center {
    vertical-align: bottom;
    width: 200px;
    text-align: center;
  }

  .dc-navigator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dc-bottom {
    margin-top: 2rem;
  }

  @media (min-width: 370px) {
    .dc-nav-center {
      display: block;
    }
  }

</style>
