











































































































.signin-form {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;

  .login-btn {
    max-height: 50px;
    .login-text {
      padding: 13px;
    }
  }
}
a.btn-google {
  color: rgba(0, 0, 0, 0.87)
}
