@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(Material+Icons_400_normal.eot); /* http://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNY.eot */
    src: local('☺'),
        url(Material+Icons_400_normal.eot?#iefix) format('embedded-opentype'), /* http://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNY.eot */
        url(Material+Icons_400_normal.woff2) format('woff2'), /* http://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2 */
        url(Material+Icons_400_normal.woff) format('woff'), /* http://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff */
        url(Material+Icons_400_normal.ttf) format('truetype'), /* http://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNZIhQ8tQ.ttf */
        url(Material+Icons_400_normal.svg#Material+Icons_400_normal) format('svg'); /* http://fonts.gstatic.com/l/font?kit=flUhRq6tzZclQEJ-Vdg-IuiaDsNb&skey=4fbfe4498ec2960a&v=v47#MaterialIcons */
}
