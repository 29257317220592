





























































































































































.dc-editor-feedback {
  .input-row-full {
    padding: 0;
    input {
      font-size: 0.7rem;
    }
  }
  .custom-select option {
      font-size: 15px;
      font-weight: 300;
  }
  .striketrough {
    text-decoration: line-through;
  }
  .verify-feedback-call-to-action a {
    color: white;
    text-decoration: underline;
  }
}
