.full-width-slider {
  box-shadow: inset 0 0 35px -6px rgba(0, 0, 0, 0.15);
  height: 340px;
  overflow: hidden;
  position: relative;
  width: 100%;

  .snijlab-slider {
    height: 340px;
    position: absolute;
    width: 200%;
    will-change: left;
  }

  .slider-image {
    display: inline-block;
    height: 300px;
    margin: 20px;
    width: 300px;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: all 0.5s;
    }

    div {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      height: 100%;
      width: 100%;
      z-index: 200;
    }
  }
}

// $sliderImageWidth: 300px;
// $sliderImageHeight: 300px;

// .full-width-slider {
//   //position: absolute;
//   transform: translateZ(0);
//   .snijlab-slider {
//     position: relative;
//     transform: translateZ(0);
//   }
//   .slider-image {
//     transform: translateZ(0);
//     width: $sliderImageWidth;
//     height: $sliderImageHeight;
//     margin: 20px;
//     background-size: cover;
//     border-radius: 5px;
//     position: inherit;
//     float: left;
//     transform: scale(1.0);
//     transition: all .5s;

//     &.clickable:hover {
//       cursor: pointer;
//       transform: scale(1.3);
//       transition: all .5s;
//     }
//   }
// }
