html,
body {
  margin: 0;
  padding: 0;
}

// stylelint-disable selector-no-qualifying-type
.snlb-use-heading-icons {
  a.hreflink i {
    visibility: hidden;
  }

  a.hreflink:hover i {
    visibility: visible;
  }

  .knowledgebase-article h1 {
    font-size: 1.8em;
  }

  .knowledgebase-article h2 {
    font-size: 1.3em;
  }
}

a {
  outline: 0;
}

// stylelint-enable selector-no-qualifying-type
