


























































.cart-reference-widget {
  .input-icon-front {
    top: -0.4rem;
  }
  input {
    font-size: 0.65rem;
  }
}
