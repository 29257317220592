


























































































































































@import '../../assets/scss/_variables.scss';

.uploaded-file-check-icon {

  &.bg-analyse {
    background-color: $color-info;
    color: $color-white;
  }

  &.bg-checked {
    background-color: $color-success;
    color: $color-white;
  }

  &.bg-warning {
    background-color: $color-warning;
    color: $color-white;
  }

  &.bg-error {
    background-color: $color-danger;
    color: $color-white;
  }
}

.uploaded-file-analyse {
  .ul-snijlab li {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
  }
}

.analyse-check {
  display: table;
  width: 100%;
  height: 100%;
}

.analyse-questionmark {
  font-family: arial;
}

.icon-analyse-check.material-icons {
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  display: table-cell;
  vertical-align: middle;
}

.uploaded-file-item {
  display: block;
  position: relative;
}

.uploaded-file-check-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $color-info;
  text-align: center;
  vertical-align: top;
  margin-right: 1rem;
}

.uploaded-file-description {
  display: inline-block;
  width: calc(100% - 3rem);
  vertical-align: top;
}

.uploaded-file-name {
  font-weight: bolder;
}

.uploaded-file-analyse {
  color: $color-text-gray;

  &.warning {
    color: $color-warning;
  }

  &.error {
    color: $color-warning;
  }
}

