







































































































.payment-widget {

  padding: 0;
  list-style-type: none;

  .list-group-item-action {
    min-height: 0;
  }

  input {
    display: none;
  }

  label {
    position:  relative;
  }

  label.selected {
    background-color: #eeeeee;
  }

  label .radio-icon {
    display: inline-block;
  }

  label .payment-label-name {
    display: inline-block;
  }

  label .payment-logo {
    float: right;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .payment-method-mollie-ideal {
    background-image: url(https://www.mollie.com/images/payscreen/methods/ideal.png);
  }

  .payment-method-mollie-creditcard {
    background-image: url(https://www.mollie.com/images/payscreen/methods/creditcard.png);
  }

  .payment-method-mollie-mistercash {
    background-image: url(https://www.mollie.com/images/payscreen/methods/mistercash.png);
  }

  .payment-method-mollie-sofort {
    background-image: url(https://www.mollie.com/images/payscreen/methods/sofort.png);
  }

  .payment-method-mollie-banktransfer {
    background-image: url(https://www.mollie.com/images/payscreen/methods/banktransfer.png);
  }

  .payment-method-mollie-directdebit {
    background-image: url(https://www.mollie.com/images/payscreen/methods/directdebit.png);
  }

  .payment-method-mollie-belfius {
    background-image: url(https://www.mollie.com/images/payscreen/methods/belfius.png);
  }

  .payment-method-mollie-paypal {
    background-image: url(https://www.mollie.com/images/payscreen/methods/paypal.png);
  }

  .payment-method-mollie-bitcoin {
    background-image: url(https://www.mollie.com/images/payscreen/methods/bitcoin.png);
  }

  .payment-method-mollie-podiumcadeaukaart {
    background-image: url(https://www.mollie.com/images/payscreen/methods/podiumcadeaukaart.png);
  }

  .payment-method-mollie-paysafecard {
    background-image: url(https://www.mollie.com/images/payscreen/methods/paysafecard.png);
  }

  .payment-method-invoice {
    background-image: url(/static/invoice.png);
  }

}
