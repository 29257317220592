.projectSearch {
  padding: 20px;
}

.categoryCheckbox {
  float: left;
  padding-right: 10px;

  .checked {
    display: none;
    visibility: hidden;
  }

  .unchecked {
    display: inherit;
    visibility: visible;
  }
}

.categoryTitleBox {
  cursor: pointer;
  padding: 10px;
  width: 100%;

  i {
    float: left;
    padding: 5px;
  }
}

.card-img-container {
  height: 200px;
  margin-bottom: 1em;
  overflow: hidden;
}

.categoryTitle,
.categorySubTitleList li {
  cursor: pointer;
  font-size: 18px;
  line-height: 35px;
}

.categorySubTitleList {
  padding-left: 40px;

  li {
    padding: 10px;
  }
}
