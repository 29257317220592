






























































































































































.menu-center {
  width: 100%;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: space-between;
  align-content: top;;
  background-color: #ffe400;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 75px;

  .user-warning {
    display: flex;
    font-size: 1rem;
    align-items: center;

    .warning-icon {
      color: rgb(231, 6, 6);
      font-size: 2rem;
      padding: 0;
      line-height: normal;
      padding-right: 0.5rem;
      font-weight: bold;
      background-color: #fff;
      text-align: center;
      width: 40px;
      padding-left: 0.5rem;
      height: 40px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    .client-name {
      margin-left: 0.4rem;
      font-weight: bold;
    }
  }
}

.admin-multiselect {
  max-width: 500px;
  min-width: 500px;

  .multiselect__option.multiselect__option--group {
    color: rgb(109, 109, 109);
    font-weight: bold;;
    padding-left: 0;
    font-size: 0.6rem;
  }

  .multiselect__option {
    color: #000;
    border-bottom: 1px solid  rgb(221, 221, 221);
    padding-top: 0.3rem;
    // padding-left: 1.5rem;
    font-size: 0.8rem;
    // padding-left: 0.5rem;
    // padding-bottom: 0.5rem;
    // background-color: rgb(190, 190, 190);
    .option__action {
      display: none;
      font-style: italic;
      color:rgb(109, 109, 109);
    }
    .option__col div {
      display: inline-block;
    }
    .option__id {
      width: 50px;
      color: #555;
    }
  }
  .multiselect__option--highlight {
    background-color: #ffe400;

    .option__action {
      display: inline-block;
    }
    // .option__action {
    //   display: inline-block;
    // }
  }

  .option__grouplabel {
    color: #000;
    font-size: 0.6rem;
  }

  .option__row {
    display: flex;
    grid-template-columns: 1fr 1fr;;
    justify-content: space-between;
  }
}
// .admin-multiselect {
//   width: 400px;
//   max-width: 400px;

//   .multiselect__option, .multiselect__option--highlight {
//     color: #000;
//     background-color: #fff;
//     border-bottom: 1px solid  rgb(177, 177, 177);
//     padding-top: 0.3rem;
//     // padding-left: 0.5rem;
//     // padding-bottom: 0.5rem;
//     // background-color: rgb(190, 190, 190);
//   }

//   .option__title {
//     font-size: .9rem;
//     display: flex;
//     justify-content: space-between;
//     .material-icons {
//       padding-top: 0.2rem;
//     }
//   }

//   .option__title .material-icons {
//     // border: 2px solid green;
//     margin-right: 0.3rem;
//   }

//   // .option__title .title-header {
//   //   font-weight: bold;
//   // }

//   // .option__title .title-text {
//   //   // border: 2px solid blue;
//   // }

//   // .option__title .btn {
//   //   // padding: 0.1rem;
//   // }

// }
