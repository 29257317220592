<template>
  <div>
    <b-modal
      id="productBatchEditor"
      ref="modal"
      :visible="visible"
      @change="updateVisible"
      no-auto-focus
      variant="danger"
      size="l">
      <div slot="modal-title">
        Bewerk geselecteerde producten
      </div>
      <div
        v-if="validData"
        class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Materiaal <i class="material-icons info-icon"
              @click.prevent.stop="showHelp('editor.material_selection')">info</i></label>
            <material-selector
              v-if="visible"
              :enabled="!locked"
              :drawing-width="boundingBox.Width"
              :drawing-height="boundingBox.Height"
              :dropdown-parent="$el"
              :locked="loading"
              :batch="true"
              v-model="materialId"
            />
          </div>
          <drawing-product-batch-list
            v-if="material"
            :material-id="materialId"
            :locked="loading"
            v-model="productIds"
            @change="testProducts"
          />
          <template v-if="statusReport">
            <div
              v-for="(item, key) in statusReport"
              :key="key">
              <template v-if="!item.valid">
                <template v-if="!item.fits">
                  <div class="alert alert-danger row">
                    <div class="col-sm-12"><strong> X{{ key }} </strong> {{ item.dc.Drawing.name }} <br>
                      Niet aangepast:<strong> Te groot voor dit materiaal</strong>
                    </div>
                  </div>
                </template>
                <template v-else-if="item.needsCopy">
                  <div class="alert alert-gray row">
                    <div class="col-sm-12"><strong> X{{ key }} </strong> {{ item.dc.Drawing.name }} <br>
                      Dit product is eerder besteld en kan niet aangepast worden. <strong>Er wordt een kopie gemaakt.</strong>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </template>
          <div class="text-right">
            <loading-button
              variant="success"
              :disabled="!canSubmit"
              :loading="loading"
              @click="updateMaterial">
              Opslaan
            </loading-button>
          </div>
        </div>
      </div>
      <div slot="modal-footer"/>
    </b-modal>
  </div>
</template>

<script>
import MaterialSelector from 'src/components/material/MaterialSelector';
import DrawingProductBatchList from 'src/components/drawingConfiguration/DrawingProductBatchList';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  name: 'DrawingConfigurationBatchEditor',
  components: {
    LoadingButton,
    DrawingProductBatchList,
    MaterialSelector,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    delta: {
      type: Number,
      default: 1.0,
    },
    replaceInCart: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      default: -1,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      locked: false,
      loading: false,
      materialId: null,
      productIds: [],
    };
  },
  watch: {
    visible() {
      this.materialId = null;
    },
  },
  computed: {
    drawingConfigurations() {
      return this.selected.map((id) => this.$store.state.dc.drawingConfiguration[id]);
    },
    boundingBox() {
      let maxX = 0;
      let maxY = 0;
      this.drawingConfigurations.forEach((dc) => {
        let { width, height } = dc.Drawing.dimensions;
        // force all products in landscape.
        if (width < height) {
          const tempWidth = width;
          // noinspection JSSuspiciousNameCombination
          width = height;
          // noinspection JSSuspiciousNameCombination
          height = tempWidth;
        }
        maxX = Math.max(maxX, width);
        maxY = Math.max(maxY, height);
      });
      return {
        Width: maxX,
        Height: maxY,
      };
    },
    feedback() {
      return ['This drawing contains text'];
    },
    // report the status for each of the materials..
    // will the material fit - and what are the other
    // concequences of this action?
    statusReport() {
      if (this.materialId === null) {
        return {};
      }

      const result = {};
      this.drawingConfigurations
      // eslint-disable-next-line arrow-body-style
        .forEach((obj) => {
          const fits = this.materialFits(
            obj.Drawing.dimensions.width,
            obj.Drawing.dimensions.height, this.materialId,
          );
          const needsCopy = obj.locked;
          const hasDrafts = obj.draftCount > 0;
          const valid = fits && !needsCopy && !hasDrafts;
          result[obj.id] = {
            fits,
            needsCopy,
            hasDrafts,
            valid,
            draftOrders: obj.draftOrders,
            dc: obj,
          };
        });
      return result;
    },
    availableProducts() {
      if (this.material) {
        return this.material.material.DrawingProducts.map((item) => this.$store.state.products[parseInt(item.id, 10)]);
      }
      return [];
    },
    material() {
      if (this.materialId in this.$store.state.materials) {
        return this.$store.state.materials[this.materialId];
      }
      return null;
    },
    validData() {
      return this.selected.length > 0;
    },
    canSubmit() {
      return Object.values(this.statusReport).length > 0 && Object.values(this.statusReport)
        .every((obj) => obj.fits);
    },
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    testProducts(val) {
      this.productIds = Object.values(val);
    },
    updateMaterial() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this
        .$store
        .dispatch('updateMaterials', {
          ids: this.selected,
          order_id: this.$store.state.cart.orderId,
          material_id: this.materialId,
          product_ids: this.productIds,
          replaceInOrders: [this.orderId],
        })
        .then(() => {
          this.hide();
        }).catch((error) => {
          this.$eventBus.error(error);
        }).finally(() => {
          this.loading = false;
        });
    },
    updateMaterialPreview(newId) {
      this.materialId = newId;
    },
    materialFits(width, height) {
      if (this.value === null) {
        return false;
      }
      const size = this.material.max_size;
      return ((Number.parseFloat(size[0]) + this.delta > width)
          && (Number.parseFloat(size[1]) + this.delta > height))
          || ((Number.parseFloat(size[1]) + this.delta > width)
            && (Number.parseFloat(size[0]) + this.delta > height));
    },
    getImage(dc) {
      return `${this.$config.imageUrl}/${dc.images.pngsmall}`;
    },
    updateVisible(value) {
      if (!value) {
        this.$emit('hide');
      }
    },
    showHelp(name) {
      this.$eventBus.$emit('snlb.showHelp', name);
    },
  },
};
</script>
<style>
  .modal-xl {
    max-width: 95%;
  }

  .popover {
    max-width: 500px !important;
  }

  .whitebg {
    background-color: #fff;
  }

  .alert-gray {
    background-color: #e3e3e3;
  }
</style>
