.snijlab-tile {
  margin: 0;
  min-height: 500px;
  padding: 0;
  text-align: center;
  width: 100%;

  & > div {
    display: flex;

    & > div {
      align-self: center;
      margin: 0 auto;
    }
  }

  &.snijlab-tile-main {
    overflow: hidden;

    h1 {
      font-size: 60px;
    }

    p {
      font-size: 24px;
    }

    .btn {
      width: 150px;
    }

    .main-title-container {
      flex-direction: column;

      & > div {
        margin-top: auto;
      }
    }
  }

  img {
    max-height: 150px;
    max-width: 90%;
  }

  ul {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  .btn {
    margin-top: 10px;
  }
}

.frontpage-video-container {
  background-color: #000000;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: -99;

  video {
    object-fit: cover;
    object-position: center;
    min-width: 100vw;
  }
}

.has-video {
  // background-color: #000;
  h1 {
    color: #ffffff;
    text-shadow: #000000 3px 0 10px;
  }

  p {
    color: #ffffff;
    text-shadow: #000000 1px 0 5px;
  }

  .btn-contrast {
    background-color: rgba(255, 255, 255, 0.5);
    background-blend-mode: multiply;
  }
}

.arrow-bounce {
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: bounce;
  text-align: center;

  a i {
    color: $color-inverse;
    font-size: 100px;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-20px);
  }
}
