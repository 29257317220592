































































































































































.container,
.container-fluid {
  margin-top: 75px;
  min-height: calc(80vh - 75px);
};
// .text-danger {
//   color: #f44336 !important;
// }

// .text-info {
//   color: #2196f3 !important;
// }

// .text-warning {
//   color: #ff9800 !important;
// }

// .text-success {
//   color: #4caf50 !important;
// }

// .alert-info, .bg-info {
//   background-color: #bbdefb;
//   color: rgba(0,0,0,.87);
// }

// .alert-warning, .bg-warning {
//   background-color: #ffe0b2 !important;
//   color: rgba(0,0,0,.87) !important;
// }

// .alert-success, .bg-success {
//   background-color: #c8e6c9 !important;
//   color: rgba(0,0,0,.87) !important;
// }

// .alert-danger, .bg-danger {
//   background-color: #ff80ab !important;
//   color: rgba(0,0,0,.87) !important;
// }

// .btn-info {
//   background-color: #2196f3 !important;
//   color: #fff !important;
// }

// .btn-success {
//   background-color: #4caf50 !important;
//   color: #fff !important;
// }

// .btn-warning {
//   background-color: #ff9800 !important;
//   color: rgba(0,0,0,.87) !important;
// }

// .btn-danger {
//   background-color: #f44336 !important;
//   color: #fff !important;
// }

//=========
// TODO: btn-admin naar daemonite?
.btn-admin, .bg-admin {
  background-color: #ffe400;
  color: #000;
}

.info-icon {
  color: rgb(160, 160, 160);
  &:hover {
    color: #000;
  }
}

.pointer {
  cursor: pointer;
}
//=========

html {
  width: 100%;
  overflow-x:hidden;

}

label {
  font-size: 15px;
}

.input-icon-front {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0.2rem;
}

.input-icon {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0.2rem;
}

// .main-container {
//   margin-top: 75px;
//   min-height: calc(100vh - 75px - 280px);
//   // max-width: 1200px;
//   margin-left: auto;
//   margin-right: auto;

//   &.frontpage {
//     max-width: 100%;
//     margin-right: calc(8px + (100% - 100vw));
//     margin-left: 0;
//   }
// }

.push-down {
  margin-top: 0px;

  .snlb-sticky-flex {
    top: 139px;
  }
}


@media (max-width: 578px) {
  .main-container {
    margin-right: 0;
  }
  .modal-body, .modal-header {
    padding: 0.625rem 0.75rem
  }
}

.form-control.has-icon {
  padding-right: 25px;
}

html body .vuedals {
  z-index: 200;
}

// .btn-success {
//   background-color: #4caf50;
//   color: #fff;
// }
// .text-success {
//   color: #4caf50 !important;
// }
// .alert-success {
//   background-color: #c8e6c9;
//   color: rgba(0,0,0,.87);
// }
// .badge-success {
//   background-color: #4caf50;
//   color: #fff;
// }

// .btn-warning {
//   background-color: #ff9800;
//   color: rgba(0,0,0,.87);
// }
// .text-warning {
//   color: #ff9800 !important;
// }
// .alert-warning {
//   background-color: #ffe0b2;
//   color: rgba(0,0,0,.87);
// }
// .badge-warning {
//   background-color: #ff9800;
//   color: rgba(0,0,0,.87);
// }

// .text-danger {
//   color: #f44336 !important
// }
// .btn-danger {
//   background-color: #f44336;
//   color: #fff;
// }
// .alert-danger {
//   color: rgba(0,0,0,.87);
//   background-color: #ffcdd2;
// }
// .badge-danger {
//   background-color: #f44336;
//   color: #fff;
// }
