
























































.slider-container {
  width: 100%;
  vertical-align: top;
  height: 350px;
  p {
    white-space: nowrap;
    padding: 0;
    margin: 0;
    a:hover {
      img {
        opacity: .5;
        cursor: pointer;
      }
    };
    img {
      position: absolute;
      width: 350px;
      height: 350px;
      padding: 0;
      margin: 0;
      box-shadow: 0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 3px rgba(0,0,0,.12),0 4px 15px 0 rgba(0,0,0,.2);
    }
  }
}
