
















































































































































































































































































































































































































































































































































































































































.vuedals .vuedal.xl {
  width: calc(100% - 3rem);
  min-height: calc(100vh - 7em);
  margin-top: 2.5em;
  position: relative;
}

.vuedals header {
  margin-bottom: 0;
}

.vuedals .vuedal {
  /*margin: 0 auto;*/
  margin: 0 auto;
  transform: none;
  left: initial;
}

.fallbackImage {
  margin-top: 10px;
  max-width: 100%;
  max-height: 500px
}

.copy-product-message {
  background-color: #e3e3e3;
}

.pricing {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: right;
  justify-items: right;
  grid-template-rows: 2fr;
}

.pricebutton-editor {
  margin-top: 0.5rem;
  display: grid;
  min-width: 150px;
  width: 100%;
  grid-template-columns: 1fr;
  align-content: center;
  align-items: center;
  text-align: right;
  .btn {
    width: 100%;
    min-width: inherit;
  }
}
.pricebutton-editor.in-cart {
  grid-template-columns: 30px 1fr;
  // width: auto;
  .btn {
    width: auto;
    // padding-right: 0;
  }
}

.pricebutton-editor.in-cart::before {
  content: '×';
  font-weight: bold;
  display: inline-block;
  text-align: center;
}

.price-total-spacer {
  display: grid;
  grid-template-columns: 1fr;
}

.price-total {
  display: grid;
  grid-template-columns: 30px 1fr;
  min-width: 150px;
  width: 100%;
  text-align: center;
  align-items: center;
  .price-total-container {
    text-align: right;
    padding-right: 1rem;
  }
  .price-total-amount {
    font-size: 1.1rem;
    text-align: right;;
  }
}

.price-total::before {
  content: '=';
  font-weight: bold;
  display: inline-block;
  text-align: center;
}

.fallback-container{
  margin: 1rem;
}

.copy-button .btn {
  margin-top: 1rem;
}

.ordered-before-container {
  margin-bottom: 1rem;
}

.download {
  .btn {
    margin-right: 10px;
  }
}

.pick-material-text {
  font-size: 0.8rem;
}

.material-link:link,
.material-link:active,
.material-link:visited {
  // color: white;
  color: #000;
  text-decoration: underline;
}
.material-link:hover {
  text-decoration: none;
}

.editor-add-to-cart .btn {
  width: 100%;
}

.no-material-help-btn {
  cursor: pointer;
}

@media (min-width: 370px) {
  .pricing {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .pricebutton-editor {
    margin-top: 0;
    grid-column: 2/3;
    grid-row: 1/2;
  }
}

@media (min-width: 500px) {
  .pricing {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
  }
}

@media (min-width: 768px) {
  .dc-editor {
    display: grid;
    grid-template-columns: 50% 50%;
    .preview-container {
      grid-row: 2/3;
      grid-column: 1/3;
    }
    .material-container {
      grid-row: 1/2;
      grid-column: 3/4;
      padding-right: 0.5rem;
    }
    .dc-navigator {
      grid-column: 1/3;
    }
    .dc-bottom {
      grid-column: 1/3;
    }
  }
  .pricing {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .pricebutton-editor {
      margin-top: 0;
      grid-column: 2/3;
      grid-row: 1/2;
    }

   .price-total {
     .price-total-container {
        padding-top: 1rem;
     }
    }
  }
}

@media (min-width: 1200px) {

  .dc-editor {
    display: grid;
    grid-template-columns: 250px 1fr 500px;
    grid-template-rows: 1fr auto;
    min-height: calc(100vh - 14em);

    .preview-container {
      grid-column: 2/3;
      grid-row: 1/3;
      height: auto;
    }
    .material-container {
      grid-column: 3/4;
      grid-row: 1/2;
      padding-right: 0.5rem;
    }
    .feedback-container {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    .dc-navigator {
      display: none;
      grid-column: 1/4;
    }
    .dc-bottom {
      grid-column: 1/4;
      .dc-navigator {
        display: flex;
      }
    }

  }
}
