
.snijlab-tile {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;

  // if has image bg then cover style:
  img {
    max-height: 150px;
    max-width: 90%;
  }

  li i {
    line-height: auto;
  }

  ol,
  ul {
    display: inline-block;
    font-size: 0.825rem;
    margin-bottom: 20px;

    li {
      line-height: 2rem;
      text-align: left;
      vertical-align: middle;
    }
  }

  ol li {
    list-style-position: inside;
    list-style-type: square;
  }
}

// min height of frontpage rows
.frontpage-element-min-heigh {
  min-height: 600px;
}
