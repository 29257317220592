<template>
  <div :class="rowClasses">
    <div class="input-row-label">
      <b-form-checkbox
        ref="input"
        :id="id"
        :checked="valuePublic"
        :disabled="disabled"
        :readonly="readonly"
        :size="size"
        :state="state"
        :value="!invertValue"
        :unchecked-value="invertValue"
        :aria-invalid="hasError"
        :autofocus="autofocus"
        @input="onInput"
      >{{ label }}
      </b-form-checkbox>
      <async-queue
        :watch-value="valuePublic"
        :delay="delay"
        @process="update"/>
    </div>
    <form-help-widget
      :error="error"
      :warning="warning"
      :help="help"/>
  </div>
</template>

<script>
import FormWidgetBase from 'src/components/form/FormWidgetBase';
import AsyncQueue from 'src/components/tools/AsyncQueue';
import VolatileField from 'src/lib/Volatile';

export default {
  name: 'FormAsyncWidgetSingleCheckbox',
  components: {
    AsyncQueue,
  },
  extends: FormWidgetBase,
  mixins: [VolatileField('value')],
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    inputValue: {
      type: Boolean,
      default: null,
    },
    inputName: {
      type: String,
      default: '',
    },
    delay: {
      type: Number,
      default: 100,
    },
    invertValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasError() {
      return Boolean(this.error);
    },
  },
  watch: {
    inputValue(newValue) {
      this.valueTaintedValue = newValue;
    },
  },
  methods: {
    onInput(value) {
      if (!this.readonly || this.disabled) {
        this.valueTaintedValue = value;
      }
    },
    update(promise, value) {
      this.$emit('update', { promise, value, inputName: this.inputName });
    },
  },
};
</script>
<style>
  .custom-control-label {
    margin-top: -10px;
  }
</style>
