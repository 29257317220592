

























































































































































































































































// h1, .fix-link::before {
//   pointer-events: none;
// }
// .download-btn, a {
//   z-index: 1;
// }
.address {
  display: block;
  border :1px solid #f2f2f2;
  margin: .5rem;
  padding: .5rem;
  max-width: 350px;
}

@media(min-width: 1200px) {
  .main-col {
    // max-width: 1200px;
  }
}
