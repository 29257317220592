






































.card.material {
  width: 10%;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
