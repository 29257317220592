






































































.project-grid-item {
  .card-body {
    cursor: pointer;
    padding-left: .5rem !important;
    padding-top: .5rem !important;
  }
}
// .card-col {
//   display: flex;
//   position: relative;
//   padding-left: 0;
//   box-shadow: 0 0 4px 0 rgba(0,0,0,.14),0 3px 4px 0 rgba(0,0,0,.12),0 1px 5px 0 rgba(0,0,0,.2);
// }
// .card-body {
//   position: absolute;
//   top: 0;
//   width: 100%;
//   height: 100%;
// }
// .card-img-container {
//   background-position: 50%;
//   background-repeat: no-repeat;
//   background-size: cover;
//   margin-bottom: 0;
// }
// .card.material {
//   width: 10%;
//   min-width: 150px;
//   margin-bottom: 1rem;
//   margin-left: 1rem;
//   display: flex;
//   flex-direction: column;
//   align-content: space-between;
//   cursor: pointer;
//   &:hover {
//     background-color: #fafafa;
//   }

//   img {
//     width: 100%;
//   }

//   .card-body {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//   }
// }
