<template>
  <b-modal
    v-if="loaded"
    v-model="visible"
    title="Prijsverloop"
  ><p>De <router-link
      :to="{ name: 'kb-page', params: { route: 'algemene-informatie' }, hash: '#prijzen' }" target="_blank">prijs</router-link>
    zakt bij hogere aantallen en stijgt bij kortere levertijd. De levertijd
    voor je order kun je aanpassen in de winkelwagen.</p>
    <p>

    <drawing-configuration-price-matrix
      :id="dcId"
      :priority-id="priority.id"
      :show-vat="showVat"/>
    <p class="quote-incentive">
      (Veel) grotere aantallen nodig? Neem dan <a href="/nl/contact">contact</a> op.
    </p>
    <template v-slot:modal-footer="{ ok }">
      <b-button size="sm" class="btn btn-primary" @click="ok()">
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import DrawingConfigurationPriceMatrix from 'src/components/drawingConfiguration/DrawingConfigurationPriceMatrix';

export default {
  name: 'PriceMatrixModal',
  components: { DrawingConfigurationPriceMatrix },
  data() {
    return {
      visible: false,
      dcId: -1,
    };
  },
  computed: {
    storeData() {
      return this.$store.state.dc.drawingConfiguration[this.dcId];
    },
    loaded() {
      return this.priority && this.storeData;
    },
    priority() {
      return this.$store.getters.selectedPriority;
    },
    showVat() {
      return this.$store.state.settings.showVat;
    },
  },
  watch: {
    $route() {
      // don't persist when the route changes..
      this.visible = false;
    },
  },
  mounted() {
    this.$eventBus.$off('snlb.showPriceMatrix', this.show);
    this.$eventBus.$on('snlb.showPriceMatrix', this.show);
  },
  methods: {
    show(dcId) {
      this.visible = true;
      this.dcId = dcId;
    },
  },
};
</script>
<style>
  .b-modal-target.vue-portal-target > div {
    position: fixed;
    z-index: 2000 !important;
  }
  .quote-incentive{
    margin-top: 20px;
  }
</style>
