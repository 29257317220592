









































































































































$bgFocus: #4ba6dd;

.no-fit {
  color: red;
}

.no-fit .aka {
  color: red;
}

.option__name_row {
  display: grid;
  grid-template-columns: 1fr 1rem;

  .option__title {
    grid-column: 1/2;
  }

  .option__color {
    height: 1rem;
    display: block;
    grid-column: 2/3;
  }
}

.material-selector-label-focus {

  .multiselect__tags {
    background: $bgFocus;
    input {
      background: $bgFocus;
      color: #ffffff;
    }
  }

  .multiselect__placeholder {
    color: #ffffff;
  }
}

.multiselect__input {
  font-weight: bold;
  color: #000;
  padding-left: 0.2rem;
}

.multiselect__option--highlight .option__aka {
  color: #F3F3F3;
}

.material-container {
  label {
    cursor: default;

    .material-icons {
      cursor: pointer;
    }
  }

  .multiselect__placeholder {
    cursor: default;
    user-select: none
  }

  .multiselect__select {
    cursor: default;
  }

  .multiselect__option--disabled {
    pointer-events: auto;

    &:hover{
      cursor: default;
      background-color: rgb(216, 216, 216);
    }
  }

  .multiselect__option--selected.multiselect__option--highlight {
    font-weight: bold;
  }
}
