/* stylelint-disable */
i.material-icons,
span.material-icons,
.material-icons {
  @include reset-material-icons;
}

.material-icons-inline {
  font-size: inherit;
  line-height: 1;
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
.pulse {
  -webkit-animation: spin 1s infinite steps(8);
  animation: spin 1s infinite steps(8);
}
@keyframes spin {
  0% {
    transform: rotate(359deg);
  }
  100% {
    transform: rotate(0deg);
  }
}