








































































































footer {
  a:link, a:visited, a:active {
    color: #fff;
    text-decoration: none;
  }

  h4 {
    font-size: 0.8rem;
  }
  .payment-icon {
    width: 35px;
    margin: 0;
    margin-right: 6px;
    margin-bottom: 6px;
  }
}
