$snlb-highlights-height: 37px !default;
$snlb-navbar-height: 75px !default;

.navbar {
  height: $snlb-navbar-height;
}

.navbar-brand {
  background-image: url('/frontend2/logo/snijlabzwart.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 100px;
  position: relative;
  left: 15px;
  width: 100px;
}

.navbar-inverse .navbar-brand {
  background-image: url('/frontend2/logo/snijlabweb-inverse.svg');
}

.nav-highlights {
  background-color: lighten(theme-color-light('dark'), 10%);
  display: none;

  a {
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    margin: 10px;
  }
}

@include media-breakpoint-up('lg') {
  .snlb-content .sidebar-menu-right {
    background-color: $color-faded;
    display: block;
    flex: 0 0 300px;
  }
}

.full-height {
  height: calc(100vh - #{$snlb-navbar-height});
}

.snlb-content {
  margin-top: $snlb-navbar-height;

  .sidebar-menu-right {
    display: block;
  }

  .center-content {
    flex: 1 1;
    min-width: 0;

    img {
      width: 100%;
    }
  }
}

.fix-link::before {
  content: '';
  display: block;
  height: $snlb-navbar-height;
  margin: (-$snlb-navbar-height) 0 0;
}

.snlb-sticky-flex {
  align-self: flex-start;
  position: sticky;
  top: $snlb-navbar-height;
}

@include media-breakpoint-up('lg') {
  .has-highlights {
    $offset: $snlb-navbar-height+$snlb-highlights-height;

    .full-height {
      height: calc(100vh - #{$offset});
    }

    .snlb-content {
      margin-top: $offset;
    }

    .snlb-sticky-flex {
      top: $offset;
    }

    .nav-highlights {
      display: block;
    }

    .fix-link::before {
      content: '';
      display: block;
      height: $offset;
      margin: (-$offset) 0 0;
    }
  }
}
