@media screen and (max-width: 1400px) {
  nav .ux-searchbar {
    display: none;
  }
}

.inline-block {
  display: inline-block;
}
// stylelint-disable selector-no-qualifying-type
nav.signed-in .signed-out {
  display: none;
}

nav.signed-in .signed-in {
  display: block;
}
// stylelint-enable selector-no-qualifying-type

nav .signed-out {
  display: block;
  white-space: nowrap;
}

nav .signed-in {
  display: none;
  white-space: nowrap;
}

.menu-language {
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  margin-right: 1em;
  padding: 0;
}

.dropdown {
  .dropdown-toggle.dropdown-toggle-no-icon::after {
    content: unset;
  }
}

.dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;

  &.dropdown-menu-dark {
    &::after,
    &::before {
      background-color: $color-inverse;
    }

    .dropdown-item {
      background-color: $color-inverse;
      color: darken(color-yiq($color-inverse), 20%);

      &:hover {
        background-color: darken($color-inverse, 5%);
        color: color-yiq($color-inverse);
      }
    }

    .dropdown-toggle {
      color: theme-color-dark('light');
    }
  }

  &.dropdown-menu-sm {
    min-width: unset;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

    .dropdown-item {
      font-size: 17.5px;
      padding: 0.35rem 1.5rem 0.35rem 1rem;
    }
  }

  .dropdown-item {
    padding: 0.25rem 1.5rem 0.5rem 1rem;
  }
}

.badge.badge-top {
  font-size: 0.8em;
  margin-top: -0.9em;
  margin-left: -0.7em;
  padding-right: 0.2em;
  padding-left: 0.2em;
  vertical-align: top;
}

.btn.btn-badge {
  padding-right: 0;
}
