// Theme palette
// Change these values to customise colour palettes

$color-primary: #4ba6dd !default;
$color-secondary: #424242 !default;
$color-success: #00b900 !default;
$color-snijlabsuccess: rgb(112, 199, 175) !default;
$color-info:    #f5f5f5 !default;
$color-warning: rgb(255, 127, 87) !default;
$color-danger:  rgb(240, 83, 101) !default;
$color-inverse: #424242 !default;
$color-faded:   #f5f5f5 !default;
$color-snijlab: #ffe400 !default;
$color-white:   #ffffff !default;

$dark-pct: 10% !default;
$lighten-pct: 10% !default;

$primary: (
  color:   $color-primary,
  dark:  darken($color-primary, $dark-pct),
  light: lighten($color-primary, $lighten-pct),
) !default;
$secondary: (
  color:   $color-secondary,
  dark:  darken($color-primary, $dark-pct),
  light: lighten($color-primary, $lighten-pct),
) !default;
$danger: (
  color:   $color-danger,
  dark:  darken($color-danger, $dark-pct),
  light: lighten($color-danger, $lighten-pct),
) !default;
$info: (
  color:   $color-info,
  dark:  darken($color-info, $dark-pct),
  light: lighten($color-info, $lighten-pct),
) !default;
$success: (
  color:   $color-success,
  dark:  darken($color-success, $dark-pct),
  light: lighten($color-success, $lighten-pct),
) !default;
$snijlab-success: (
  color: $color-snijlabsuccess,
  dark:  darken($color-snijlabsuccess, $dark-pct),
  light: lighten($color-snijlabsuccess, $lighten-pct),
) !default;
$warning: (
  color:   $color-warning,
  dark:  darken($color-warning, $dark-pct),
  light: lighten($color-warning, $lighten-pct),
) !default;
$dark: (
  color:   $color-inverse,
  dark:  darken($color-inverse, $dark-pct),
  light: lighten($color-inverse, $lighten-pct),
) !default;
$light: (
  color:   $color-faded,
  dark:  darken($color-faded, $dark-pct),
  light: lighten($color-faded, $lighten-pct),
) !default;

$white: (
  color: $color-white,
  dark:  $color-white,
  light: $color-white,
) !default;
$snijlab: (
  color:   $color-snijlab,
  dark:  darken($color-snijlab, $dark-pct),
  light: lighten($color-snijlab, $lighten-pct),
) !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    primary:   $primary,
    secondary: $secondary,
    danger:    $danger,
    info:      $info,
    success:   $success,
    warning:   $warning,
    dark:      $dark,
    light:     $light,
    snijlab:   $snijlab,
    snijlab-success: $snijlab-success,
  ),
  $theme-colors
);

$yiq-contrasted-threshold: 150 !default;
$yiq-text-dark:            $black-primary !default;
$yiq-text-light:           $white-primary !default;
