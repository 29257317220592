


































































.drawing-product-list {
  .form-group label {
    width: 100%
  }
}
