.multiselect-native-select {
  li {
    // stylelint-disable selector-no-qualifying-type
    label.checkbox {
      padding: 20px;
      width: 100%;
    }
    // stylelint-enable selector-no-qualifying-type
  }

  .btn-group {
    width: 100%;

    .multiselect-container {
      width: 100%;
    }
  }
}
