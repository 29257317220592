.button-container {
  display: flex;
  justify-content: space-between;
}

.signin-modal .login-panel {
  /* display: none; */
}

.signin-modal.login .login-panel {
  display: block;
}

.signin-modal.login .create-account-panel {
  /* display: none; */
}

.form-dark input {
  border-color: #dddddd;
  color: #ffffff;
}

.form-dark label {
  color: #ffffff;
  font-size: 0.825rem;
}

.form-dark input:hover,
.form-dark input:focus,
.form-dark .form-control input:hover,
.form-dark .form-control input:focus {
  border-color: #ffffff;
  box-shadow: inset 0 -2px 0 -1px rgba(255, 255, 255, 0.87);
  color: #ffffff;
}

.form-dark input::placeholder {
  color: #eeeeee;
  font-weight: 300;
}

.form-dark a {
  color: #ffffff;
}

.form-dark .custom-select {
  appearance: none;
  border-color: #eeeeee;
  color: #ffffff;

  @include media-moz-webkit {
    background-image: $caret-bg-white;
  }

  &:hover {
    box-shadow: inset 0 -2px 0 -1px rgba(255, 255, 255, 0.87);
  }

  &:focus {
    box-shadow: inset 0 -2px 0 -1px rgba(255, 255, 255, 0.87);
  }
}

.form-dark .custom-select option {
  color: #000000;
}

.form-dark .custom-select option:disabled {
  color: #555555;
}

.btn-tab {
  border-radius: 0;
  font-size: 175%;
  font-weight: 300;
  padding-top: 15px;
  text-align: left;
}

.btn-tab.selected {
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin-left: 10px;
}

// stylelint-disable selector-no-qualifying-type
input[type='number'] {
  appearance: textfield;
  text-align: right;
}
// stylelint-enable selector-no-qualifying-type
