







































































































































































































































































































































































































@import '../../assets/scss/_variables.scss';

  #fileUploader {
    .modal-header {
      padding-top: 0;
      margin-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .modal-footer {
      display: none
    }

    .stepper-horiz .stepper {
      .stepper-icon {
        // background-color: rgb(117, 117, 117);
        font-size: .7rem;
      }
      .stepper-text {
        font-size: .8rem;
        // color: rgb(117, 117, 117);
      }
    }
    .upload-icon {
      font-size: 1.2rem;
    }
    .dropbox {
      position: relative;
      text-align: center;

      .dropbox-title {
        font-size: 1.5rem;
        .material-icons {
          font-size: 2.2rem;
        }
        .filetypes-msg {
          font-size: 0.9rem;
        }
      }
    }
  }

  .notify-uploading {
    font-size: 1.2rem;
  }

  .upload-comment-list {
    list-style: outside;
    // padding-inline-start: 3rem;
    text-align: left;
  }

  .selected-files {
    // padding-left: 0;

    .uploaded-file {
      // padding: 8px 8px 0;
      font-size: .75rem;

      p {
        margin-bottom: 8px;
      }

      ul {
        margin-top: 0;
        line-height: .8rem;
      }

      .material-icons {
        line-height: .8rem;
      }
    }
  }

  .uploader-button-bar {
    display: flex;
    align-items: top;
    justify-content: space-between;
    .multiple-material-tip {
      width: 60%;
    }
  }

  .fullscreen {
    font-size: 2em;
    color: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(51, 51, 51, 0.7);
    z-index: 10000000;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fullscreen .upload-message {
    text-align: center;
    border: 2px dashed $color-info-border;
    border-radius: .5rem;
  }
