



























































































































































































































































































































































































































































































































































































































































































.cart-list {
  // display: flex;
  // margin: 1rem;

  .cart-upload-block .btn {
    white-space: normal;
  }

  .btn-container-center {
    align-content: center;
  }

  .cart-reference-widget {
    .input-row-full, .form-control {
      padding-top: 0;
    }
  }

  .card-sticky {
    position: sticky;
    top: 107px;
    z-index: 6;
  }

  .medium-price {
    font-size: 0.9rem;
  }

  .large-price {
      font-size: 1.5em;
  }

  .coupon-container {
    display: grid;
    grid-template-columns: 1fr 130px;
    justify-content: flex-start;

    .coupon-label {
      grid-column: 1/2;
    }
    .coupon-price {
      grid-column: 2/3;
    }
  }

  .coupon-add-field-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-container {
      width: 100%;
    }
  }

  .cart-main-block {
    padding-bottom: 0;
  }

  .cart-list-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    background-color: #fff;
    // box-shadow: 0 5px 12px -5px rgba(163, 161, 163, 1);

    .cart-list-header-select-all {
      display: flex;
    }

    .cart-list-header-actions {
      display: grid;
      justify-items: flex-end;
    }
  }

  .cart-list-totals {
    .cart-list-totals-row {
      display: flex;
      justify-content: space-between;
    }
  }

  .button-my-products {
    overflow: hidden;
  }

  .upload-hint {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;

    h3 {
      margin: auto;
      max-width: 800px;
      border: 2px dashed #777;
      font-size: 1.8rem;
      border-radius: .5rem;
    }

    h3 {
      line-height: 100%;
    }
    .filetypes-msg {
      font-size: 1.2rem;
    }
  }

  .upload-button-divider {
    font-size: 1.2rem;
  }

  // .upload-file-btn {
  //   margin-bottom: .5rem;
  //   margin-right: 10px;
  // }

  // .existing-file-btn {
  //   margin-bottom: .5rem;
  //   margin-left: 10px;
  // }

  // @media (max-width: 700px) {
  //   .cart-list {
  //     border: 10px solid red;
  //     margin: 0;
  //   }
  // }

  .cart-list-col-settings {
    .to-top-spacer {
      height: 300px;
      background-color: #fff;
    }

    .to-top-btn {
      position: sticky;
      top: calc(100% - 60px);
      z-index: 0;
      float: right;
    }
  }

  // .cart-list-col-body {
  //   width: 100%;
  // }

  // .cart-list-uploader {
  //   border: 10px solid red;
  //   margin-bottom: 1rem;
  // }

  // @media (max-width: 992px) {
  //   .order-priority-list {
  //     margin-top: 1rem;
  //   }
  //   .shipping-cards {
  //     margin-top: 1rem;
  //   }
  //   .cart-list {
  //     flex-direction: row;
  //     flex-flow: row wrap;
  //   }
  //   .cart-list-col-settings {
  //     width: 100%;
  //   }
  //   .cart-list-col-body {
  //     width: 100%;
  //     margin-left: 0;
  //     margin-top: 1rem;
  //   }

  //   .cart-list-col-settings {
  //     display: flex;

  //     .shipping-cards, .order-priority-list {
  //       width: 50%;
  //     }

  //     .order-priority-list {
  //       margin-left: 1rem;
  //     }
  //   }
  // }

  // @media (max-width: 576px) {
  //   .cart-upload-block .btn {
  //     width: 100%
  //   }

  //   .upload-file-btn,
  //   .existing-file-btn {
  //     margin-left: 0;
  //     margin-right: 0;
  //   }

  //   .button-my-products {
  //     font-size: 0.825em;

  //     h3 {
  //       font-size: 1.35em;
  //       font-weight: normal;
  //       padding-top: 10px;
  //       padding-bottom: 0;
  //     }
  //   }

  //   .cart-list-header {
  //     display: none;
  //   }

  //   .cart-list {
  //     flex-direction: row;
  //     flex-flow: row wrap;
  //   }
  //   .cart-list-col-settings {
  //     width: 100%;
  //   }
  //   .cart-list-col-body {
  //     width: 100%;
  //     margin-left: 0;
  //     margin-top: 1rem;
  //   }

  //   .cart-list-col-settings {
  //     display: block;

  //     .shipping-cards, .order-priority-list {
  //       width: inherit;
  //     }

  //     .order-priority-list {
  //       margin-left: 0;
  //       margin-top: 1rem;
  //     }
  //   }
  // }

  // @media (max-width: 768px) {
  //   .cart-list-uploader {
  //     display: none;
  //   }
  // }
}

