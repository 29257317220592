<template>
  <div
    :class="{ loading: loading }"
    class="alert alert-warning errors"
    @click="highlight()">
    <ul>
      <li v-for="(item, key) in cart.orderErrors"
        :key="key">
        <i class="material-icons compact">warning</i>
        {{ getMessage(item) }}
        <i v-if="!(hideHelp.indexOf(item) !== -1)"
          class="material-icons compact info-icon"
          @click="showHelp(item)">info</i>
      </li>
    </ul>
    <div v-if="cart.orderErrors.length > 0">
      <!-- Je kan dit doen in je
      <router-link
        :to="{ name: 'order-cart', params: { orderId: cart.orderId } }">
        winkelwagen
      </router-link> -->
    </div>
    <ul>
      <li v-if="Object.keys(cart.addressErrors).length > 0 || Object.keys(cart.addressWarnings).length > 0">
        <i class="material-icons">warning</i>
        Vul alle verplichte velden in.
        <a class="badge badge-light" href="#" @click="$emit('showFormErrors')">verplichte velden tonen</a>
      </li>
      <li v-if="paymentMethod === 'invalid'">
        <i class="material-icons">warning</i> Kies een geldige betaalwijze
      </li>
    </ul>

    <div class="mt-2">
      <strong>Kom je er niet uit?</strong> Mail naar
      <a href="mailto:info@snijlab.nl">info@snijlab.nl</a>
      of bel <a href="tel:0031103070949">+31 (0)10-3070949</a> en vermeld ordernummer
      <strong>L{{ cart.orderId }}.</strong>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ErrorComponent',
  props: {
    paymentMethod: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      cartErrors: ['ORDER_DRAWING_CONFIGURATION_INVALID', 'ORDER_DRAWING_CONFIGURATION_NO_MATERIAL',
        'ORDER_DRAWING_CONFIGURATION_NOT_APPROVED', 'ORDER_EMPTY', 'ORDER_MINIMUM_AMOUNT_NOT_MET'],
      hideHelp: ['ORDER_DRAWING_CONFIGURATION_INVALID', 'ORDER_DRAWING_CONFIGURATION_NO_MATERIAL',
        'ORDER_DRAWING_CONFIGURATION_NOT_APPROVED', 'ORDER_EMPTY', 'ORDER_MINIMUM_AMOUNT_NOT_MET'],
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  methods: {
    getMessage(item) {
      return this.$store.state.popups[`cart.${item}`.toLowerCase()].title;
    },
    showHelp(item) {
      this.$eventBus.$emit('snlb.showHelp', `cart.${item}`);
    },
    highlight() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 750);
    },
  },
};
</script>

<style>
  .errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
</style>
