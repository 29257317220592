// Setup the function in your functions or helpers files, wherever you keep these bits.
@function headings($from:1, $to:6) {
  @if $from==$to {
    @return 'h#{$from}';
  }

  @else {
    @return 'h#{$from},'+headings($from+1, $to);
  }
}

// Then call it with the rules/styles you want wherever you want (As long as the file/include has access to the above function of course).
#{headings(1,6)} {
  line-height: 150%;
}

p {
  font-weight: 300;
  line-height: 150%;
}

ul,
ol {
  font-weight: 300;
  line-height: 150%;
}

h4,
h5 {
  font-weight: 500;
}

.snijlab {
  h1 {
    font-size: 250%;
    margin-top: 0.3em;
  }

  h2 {
    font-size: 175%;
  }

  h3 {
    font-size: 125%;
  }

  h4 {
    font-size: 110%;
  }

  h5 {
    font-size: 100%;
  }
}
