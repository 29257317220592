





































































































































.list-group-delivery {

  .selected-icon {
    display: flex;
    align-items: center;
  }
  .radio-icon {
    display: inline-block;
  }

  .shipping-label-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    line-height: normal;
  }
  .shipping-label-name {
    // padding-left: 25px;
    display: inline-block;
  }
  .priority-shipping-price {
    font-size: 0.9em;
    line-height: normal;
  }

  .priority-radio-container {
    display: grid;
    grid-template-columns: 30px auto 110px;
  }
  // .shipping-icon {
  //   float: right;
  //   background-repeat: no-repeat;
  //   font-size: 1rem;
  // }

  .list-group-item-action {
    cursor: pointer;
    min-height: 0;
  }

  .priority-country-selector {
    grid-column: 2/4;
    grid-row: 2/3;
    font-size: 0.9rem;
  }

}
