





























































































































































































































.main-menu-wrapper {
  width: 100%;
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
}
.popup-menu .material-icons {
  padding-right: .6rem;
}

.dropdown.no-icon .dropdown-toggle::after {
  display: none;
}

.btn-group.no-box {
  box-shadow: none;
}

.main-menu-logo {
  width: 10rem;
}

.logo-snijlab {
  background-image: url('https://www.snijlab.studioavw.nl/frontend2/logo/snijlabweb-inverse.svg');
  background-repeat: no-repeat no-repeat;
  background-position: center center;
}

.menu-left {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: flex-end;
}

.menu-right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: flex-end;
  justify-self: flex-end;
  justify-content: flex-end;
}

.menu-popup {
  position: relative;
}

.username-item {
  font-size: 1rem;
  padding: 0.5rem;
}

.main-menu-items .menu-popup {
  .menu-1200, .menu-600, .menu-500, .menu-400, .menu-300 {
    display: none;
  }
}

.main-menu-items {
  .menu-1200, .menu-600, .menu-500, .menu-400, .menu-300 {
    display: flex;
  }
}

.menu-separator {
  margin: 5px;
  border-bottom: 1px solid #aaaaaa;
}

.hamburgerIcon .material-icons {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .main-menu-items {
    padding-left: 1.1rem;
  }
  .main-menu-items .menu-logo.logo-snijlab {
    width: 115px;
  }

  .main-menu-items .menu-item a {
    &.hamburgerIcon {
      display: flex;
    }
  }

  .main-menu .popup-menu .menu-1200 {
    display: flex;
  }

  .main-menu .main-menu-items .menu-search, .main-menu .main-menu-items .menu-search-input {
    display: none;
  }
}

@media (max-width: 992px) {
  .main-menu .main-menu-items .menu-item a {
    font-size: 0.7rem;
  }

  .main-menu-items .menu-logo.logo-snijlab {
    width: 75px;
  }
}

@media (max-width: 768px) {
  .main-menu {
    grid-template-columns: 1fr !important;
    grid-template-rows: 37.5px 37.5px;
    .menu-left {
      margin: 0;
      grid-column: 1/2;
      grid-row: 2/3;
    }
    .menu-right {
      margin: 0;
      grid-column: 1/2;
      grid-row: 1/2;
      padding-top: 10px;
    }
  }

  .main-menu .menu-left .main-menu-items .menu-item a {
    padding: 0.3rem;
  }

  .main-menu .menu-left .main-menu-items {
    padding-left: 0.7rem;
    margin: 0;
  }

  .menu-right .main-menu-items {
    padding-left: 0.7rem;
    margin: 0;
    .popup-menu {
      top: 66px;

      .menu-search-input-el {
        margin: 0.3rem;
        height: 1.5rem;
      }
    }
    .popup-menu li {
      padding: 0;
    }
    .menu-item a {
      padding-top: 0.3em;
      padding-bottom: 0.3em;
    }
  }

  .main-menu-items .menu-logo.logo-snijlab {
    position: absolute;
    top: 0.45rem;
    left: 1rem;
  }

  .main-menu .menu-lang {
    display: none;
  }
}

@media (max-width: 370px) {
  .main-menu .main-menu-items .menu-item.menu-600 {
    display: none;
  }

  .main-menu .main-menu-items .popup-menu .menu-600 {
    display: flex;
  }
}

.popup-menu {
  position: absolute;
  right: 0;
  top: 55px;
  width: 250px;

  ul li {
    list-style-type: none;
  }

  ul {
    padding: 0;
  }

  li.search {
    display: none;
    /*display: flex;*/
    padding: 5px;
  }

  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  li.language {
    display: flex;
    span {
      padding: 10px;
    }
    a {
      width: 33%;
    }
  }
}

.menu-lang {
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  display: flex;
  flex-direction: column;

  a {
    color: #ffffff;
    opacity: 0.8;
  }

  a:hover, a:focus, a:active {
    color: #ffffff;
  }
}


.main-menu {
  z-index: 101;
  background-color: #424242;
  color: #fff;
  height: 75px;
  width: 100vw;
  display: grid;
  grid-template-columns: 2fr 1fr;
  min-width: 280px;
}
.main-menu-admin {
  grid-template-columns: 2fr 4fr 1fr;
}

.main-menu-items {
  margin-top: 15px;
  list-style-type: none;
  display: flex;
  align-items: center;

  .menu-item.menu-cart {
    position: relative;
    padding-right: 0.35rem;
    padding-left: 0.15rem;

    a {
      opacity: 1;
    }

    .badge {
      font-size: .6rem;
      height: 20px;
      margin-top: -10px;
      padding: 2px;
    }
  }

  .menu-logo {
    width: 150px;
    height: 35px;
  }

  .menu-logo a, .menu-logo button {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .menu-search-input {
    align-items: center;
    display: flex;
    color: #fff;
    padding: 0;
    position: relative;
  }

  .menu-search-input-el {
    padding: 10px;
    height: 2.1rem;
    background-color: rgba(220, 220, 220, 0.1);
    border: none;
    color: #fff;

    &::placeholder {
      color: #ccc;
    }

    font-weight: lighter;

    &:focus {
      outline: rgba(255, 228, 0, 0.5) 1px solid;
    }
  }


  .menu-item {
    &.menu-sign-in {
      a {
        color: #ffffff;
        opacity: 1;
        font-weight: bolder;
      }

      padding-left: 10px;
      padding-right: 10px;
    }

    &.menu-item-order {
      border: 1px solid rgb(255, 228, 0);

      a {
        color: rgb(255, 228, 0);
        font-weight: bolder;
        padding-left: 20px;
        padding-right: 20px;
        opacity: 1;
      }

      border-radius: 2px;
    }

    .material-icons {
      line-height: 1.1rem;
    }

    &.no-fade {
      a {
        opacity: 1;
      }
    }

    a {
      display: flex;
      padding: 0.5rem;
      color: #fff;
      opacity: 0.8;
      font-weight: lighter;
      font-size: 0.825rem;
    }

    &:hover {
      background-color: rgba(220, 220, 220, 0.1);

      a {
        opacity: 1;
        text-decoration: none;
      }
    }

    a:focus, a:hover, a:active {
      text-decoration: none;
    }

    // a:active {
    //   outline: 1px dotted rgba(255, 228, 0, 0.5);
    // }

    // &:focus {
    //   outline: 1px dotted #ccc;
    // }

    &.active {
      a {
        opacity: 1;
        font-weight: bolder;
      }
    }

    &.menu-item-admin {
      background-color: #ffe400;
      a {
        color: #000;
      }
      &:hover {
        background-color: #e6cf00;
      }
    }
  }
}
