@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: url(Fira+Sans_400_normal.eot); /* http://fonts.gstatic.com/s/firasans/v9/va9E4kDNxMZdWfMOD5Vvl4jP.eot */
    src: local('☺'),
        url(Fira+Sans_400_normal.eot?#iefix) format('embedded-opentype'), /* http://fonts.gstatic.com/s/firasans/v9/va9E4kDNxMZdWfMOD5Vvl4jP.eot */
        url(Fira+Sans_400_normal.woff2) format('woff2'), /* http://fonts.gstatic.com/s/firasans/v9/va9E4kDNxMZdWfMOD5Vvl4jL.woff2 */
        url(Fira+Sans_400_normal.woff) format('woff'), /* http://fonts.gstatic.com/s/firasans/v9/va9E4kDNxMZdWfMOD5Vvl4jN.woff */
        url(Fira+Sans_400_normal.ttf) format('truetype'), /* http://fonts.gstatic.com/s/firasans/v9/va9E4kDNxMZdWfMOD5Vvl4jOazX3dA.ttf */
        url(Fira+Sans_400_normal.svg#Fira+Sans_400_normal) format('svg'); /* http://fonts.gstatic.com/l/font?kit=va9E4kDNxMZdWfMOD5Vvl4jM&skey=6bde03e5f15b0572&v=v9#FiraSans */
}
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    src: url(Fira+Sans_300_normal.eot); /* {{embedded-opentype-gf-url}} */
    src: local('☺'),
        url(Fira+Sans_300_normal.eot?#iefix) format('embedded-opentype'), /* {{embedded-opentype-gf-url}} */
        url(Fira+Sans_300_normal.woff2) format('woff2'), /* http://fonts.gstatic.com/s/firasans/v9/va9B4kDNxMZdWfMOD5VnPKreRhf6.woff2 */
        url(Fira+Sans_300_normal.woff) format('woff'), /* http://fonts.gstatic.com/s/firasans/v9/va9B4kDNxMZdWfMOD5VnPKreRhf8.woff */
        url(Fira+Sans_300_normal.ttf) format('truetype'), /* http://fonts.gstatic.com/s/firasans/v9/va9B4kDNxMZdWfMOD5VnPKreRhf_Xl7Glw.ttf */
        url(Fira+Sans_300_normal.svg#Fira+Sans_300_normal) format('svg'); /* {{svg-gf-url}} */
}
