









































.loading-widget-full-width {
  width: 100%;
  min-height: 75vh;
  display: flex;
  align-content: center;
  justify-content: center;
}
