























































































$bgFocus: #4ba6dd;

.no-fit {
  color: red;
}

.no-fit .aka {
  color: red;
}

.option__name_row {
  display: grid;
  grid-template-columns: 1fr 1rem;

  .option__title {
    grid-column: 1/2;
  }

  .option__color {
    height: 1rem;
    display: block;
    grid-column: 2/3;
  }
}

.material-selector-label-focus {

  .multiselect__tags {
    background: $bgFocus;
    input {
      background: $bgFocus;
      color: #ffffff;
    }
  }

  .multiselect__placeholder {
    color: #ffffff;
  }
}

.multiselect__option--highlight .option__aka {
  color: #F3F3F3;
}
