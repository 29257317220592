





































































































































































































































































































































































.dc-list-item {
  border-bottom: 1px dotted #ddd;
  display: grid;
  grid-template-columns: 395px 1fr 450px;
  box-sizing: border-box;

  &:hover {
    background-color: rgb(250,250,250);
  }

  &.selected {
    background-color: rgba(186, 223, 255, 0.25);
    &:hover {
      background-color: rgba(186, 223, 255, 0.4);
    }
  }

  .dc-list-item-price {
    .btn {
      width: 150px;
    }
  }

  .order-list {
    padding-left: 0;
  }

  .dc-list-item-image {
    img {
      max-height: 200px;
      width: auto;
    }
  }

  .dc-list-item-select .custom-control.custom-checkbox {
    min-height: auto;
  }

  .dc-list-item-select,
  .dc-list-item-id,
  .dc-list-item-specification {
    cursor: pointer;
  }

  // .dc-list-item-actions .row-actions {
  //   width: 100%;
  // }

  .dc-list-item-specification {
    padding: 17.5px 10px 10px;
  }
}

.btn-empty {
  border-bottom: 1px solid #eeeeee;
}

.dc-list-item-first-column {
  display: grid;
  grid-template-columns: 75px 100px 220px;
}

.dc-list-item-first-column > .dc-list-item-select {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.dc-list-item-first-column > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dc-list-item-image {
  padding: 10px;
  cursor: zoom-in;
}

.dc-list-item-last-column {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 165px 150px 170px;
}

.dc-list-item-last-column > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (min-width: 1200px) {
  .dc-list-item {
    padding-right: 15px;
  }
}

@media (max-width: 1200px) {
  .dc-list-item {
    display: grid;
    grid-template-columns: 395px 1fr 300px;
  }
  .dc-list-item-last-column {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 165px 125px;
    grid-template-rows: 1fr 1fr;
  }
  .dc-list-item-cart {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    /*border: 1px solid;*/
    align-items: flex-end;
    padding-bottom: 15px;
  }
  .dc-list-item-price {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: flex;
    align-items: flex-start;
    /*border: 1px solid;*/

  }
  .dc-list-item-actions {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}

@media (max-width: 992px) {
  .dc-list-item {
    display: grid;
    grid-template-columns: 220px 1fr 320px;
  }
  .dc-list {
    margin: 0;
  }
  .dc-list-item-first-column {
    grid-template-columns: 65px 170px;
    grid-template-rows: 50px 1fr;
  }
  .dc-list-item-first-column > .dc-list-item-select {
    justify-content: flex-start;
  }

  .dc-list-item-first-column > .dc-list-item-id {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    display: flex;
    justify-content: flex-start;
  }

  .dc-list-item-first-column > .dc-list-item-image {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    display: flex;
    padding: 10px 10px 20px;
  }
}

@media (max-width: 768px) {
  .dc-list-item {
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-template-rows: 1fr 100px;
  }
  .dc-list-item-first-column {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  .dc-list-item-specification {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }
  .dc-list-item-last-column {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

@media (max-width: 576px) {
  .dc-list-item-first-column > .dc-list-item-select {
    display: none;
  }
  .dc-list-item-first-column {
    padding-left: 15px !important;
  }
  .dc-list-item-first-column > .dc-list-item-id {
    justify-content: flex-start;
    grid-column: 1/2;
  }

  .dc-list-item {
    font-size: 14px;
    display: grid;
    grid-template-columns: minmax(160px, 1fr) 2fr;
  }

  .dc-list-item-first-column {
    grid-template-columns: 50px 1fr;
  }

  .dc-list-item-last-column {
    grid-template-columns: minmax(160px, 1fr) 2fr;
    padding-right: 5px;
  }

  .dc-list-item-price, .dc-list-item-cart {
    padding-right: 5px;
    padding-left: 5px;
  }


  .dc-list-item-actions {
    padding-left: 5px;
  }
  .dc-list-item-specification {
    padding-left: 0;
  }

  .dc-list-item-actions {
    padding: 0;
    margin: 0;
  }
;

  .dc-list-item-first-column {
    grid-template-columns: 50px 1fr;
  }

  .dc-list-item-image {
    width: 100%;

  }

  .card-header.sidecolumn {
    display: none;
  }
  .card.p-3.m-3.text-center.btn {
    display: none;
  }

}


@media (max-width: 300px) {
  .dc-list-item {
    display: block
  }
  .dc-list-item-last-column {
    grid-template-columns: 100%;
    display: block;
    padding-bottom: 20px;
  }

  .dc-list-item-specification {
    padding-left: 15px;
  }
  .dc-list-item-last-column > div {
    padding-left: 5px;
    padding-top: 5px;
  }
  .dc-list-item-price, .dc-list-item-price div {
    width: 100%;
  }
  .dc-list-item-cart {
    padding-top: 20px !important;
  }
  .dc-list-item > div {
    width: 100%
  }
}
/*
.dc-list-item-id
.dc-list-item-image
.dc-list-item-specification
.dc-list-item-price
.dc-list-item-cart
.dc-list-item-actions*/
