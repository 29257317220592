// bron https://gist.github.com/lmeyer/5322b61279023359aa3dda7c41513e7b
// Sizes
$margin-none: 0 !default;
$margin-sm: 5 !default;
$margin-base: 15 !default;
$margin-lg: 30 !default;
$margin-xl: 50 !default;
$margin-xxl: 100 !default;
$padding-xxl: 100 !default;
$padding-xl: 50 !default;
$padding-lg: 30 !default;
$padding-base: 15 !default;
$padding-sm: 5 !default;
$padding-none: 0 !default;
$screen-sm-min: 576px !default;
$screen-md-min: 768px !default;
$screen-lg-min: 992px !default;

@mixin make-paddings($class, $padding) {
  .p-#{$class}-#{$padding} {
    padding: $padding * 1px;
  }

  .pl-#{$class}-#{$padding} {
    padding-left: $padding * 1px;
  }

  .pr-#{$class}-#{$padding} {
    padding-right: $padding * 1px;
  }

  .pt-#{$class}-#{$padding} {
    padding-top: $padding * 1px;
  }

  .pb-#{$class}-#{$padding} {
    padding-bottom: $padding * 1px;
  }

  .pv-#{$class}-#{$padding} {
    @extend .pt-#{$class}-#{$padding};
    @extend .pb-#{$class}-#{$padding};
  }

  .ph-#{$class}-#{$padding} {
    @extend .pl-#{$class}-#{$padding};
    @extend .pr-#{$class}-#{$padding};
  }
}

@mixin make-margins($class, $margin) {
  .m-#{$class}-#{$margin} {
    margin: $margin * 1px;
  }

  .ml-#{$class}-#{$margin} {
    margin-left: $margin * 1px;
  }

  .mr-#{$class}-#{$margin} {
    margin-right: $margin * 1px;
  }

  .mt-#{$class}-#{$margin} {
    margin-top: $margin * 1px;
  }

  .mb-#{$class}-#{$margin} {
    margin-bottom: $margin * 1px;
  }

  .mv-#{$class}-#{$margin} {
    @extend .mt-#{$class}-#{$margin};
    @extend .mb-#{$class}-#{$margin};
  }

  .mh-#{$class}-#{$margin} {
    @extend .ml-#{$class}-#{$margin};
    @extend .mr-#{$class}-#{$margin};
  }
}

@mixin make-all-paddings($class) {
  @include make-paddings($class, $padding-none);
  @include make-paddings($class, $padding-sm);
  @include make-paddings($class, $padding-base);
  @include make-paddings($class, $padding-lg);
  @include make-paddings($class, $padding-xl);
  @include make-paddings($class, $padding-xxl);
}

@mixin make-all-margins($class) {
  @include make-margins($class, $margin-none);
  @include make-margins($class, $margin-sm);
  @include make-margins($class, $margin-base);
  @include make-margins($class, $margin-lg);
  @include make-margins($class, $margin-xl);
  @include make-margins($class, $margin-xxl);
}

@include make-all-paddings(xs);
@include make-all-margins(xs);

@media (min-width: $screen-sm-min) {
  @include make-all-paddings(sm);
  @include make-all-margins(sm);
}

@media (min-width: $screen-md-min) {
  @include make-all-paddings(md);
  @include make-all-margins(md);
}

@media (min-width: $screen-lg-min) {
  @include make-all-paddings(lg);
  @include make-all-margins(lg);
}
