

































































































.user-settings .card {
  max-width: 50%;
  margin: 20px auto 0;
  // padding-top: 20px;
}

@media (max-width: 992px) {
  .user-settings .card {
    max-width: 80%;
  }
}

@media (max-width: 578px) {
  .user-settings .card {
    max-width: 100%;
  }
}

