













































































.signin-modal {
  header, footer {
    display: none;
  }
  .modal-dialog {
    max-width: 50rem;
  }
  .modal-body {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
  /*.modal-*/
}

.login-panel {
  display: flex;
}
