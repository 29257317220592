// stylelint-disable selector-no-qualifying-type
i.material-icons {
  &.tiny {
    font-size: 1rem;
  }

  &.small {
    font-size: 2rem;
  }

  &.medium {
    font-size: 4rem;
  }

  &.large {
    font-size: 6rem;
  }
}

// stylelint-enable selector-no-qualifying-type
