














































































































































































































































































.svg-buttons {
  display: flex;
  justify-content: space-between;
}
  .btn-toolbar-right {
      display: flex;
      .btn {
        min-width: inherit;
      }
      justify-content: flex-end;
  }

  .preview .bg-error {
    background-color: red;
    height: 100%;
  }

  .btn.btn-outline {
    margin: 3px;
    font-weight: normal;
    background-image: none;
    background-color: #ffffff;
    &.active {
      border-width: 2px;
      margin: 2px;
      background-image: none;
    }
  }
  .btn.btn-all {
    .material-icons {
      color: #000;
    }
  }

  .btn.btn-cutting {
    .material-icons {
      color: #00f;
    }
  }

  .btn.btn-ignored {
    .material-icons {
      color: #aaaaaa;
    }
  }

  .btn.btn-help {
    .material-icons {
      color: #000000;
    }
  }

  .btn.btn-area-engrave {
    .material-icons {
      color: magenta;
    }
  }

  .btn.btn-line-engrave {
    .material-icons {
      color: red;
    }
  }

  .svg-buttons {
    margin-top: .2rem;
    .btn-outline-secondary {
      border: 1px solid rgba(0,0,0,.2);
      padding-left: .5rem;
      padding-right: .5rem;
    }
    .btn:disabled,
    .btn[disabled] {
      .material-icons {
        opacity: .6;
      }
    }
 }

  .btn.btn-outline:hover, .btn.btn-outline:active, .btn.btn-outline:focus {
    background-color: #ffffff;
    background-image: none;
    font-weight: normal;
  }

  .svg-loading-widget {
    position: absolute;
    margin-top: 45px;
    z-index: 1;
  }
