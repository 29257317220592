



















































































































































































































































































































.email-modal {
  .email-template-lang-container .form-check-label {
    padding-top: 3px;
  }
  .variables-table {
    th, td {
      padding: 0.1rem;
      height: auto;
    }
  }
}
