.knowledgebase-article {
  padding: 0 32px 32px 32px;

  img {
    width: 100%;
  }
}

.knowledgebase-chapters {
  padding: 40px;

  #{headings(1,5)} a {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

.mainContent .knowledgebase-article {
  padding-left: 20px;

  ul {
    list-style: square outside;
  }

  ol {
    list-style-position: outside;
  }

  ul,
  ol {
    li {
      padding: 0;
      padding-left: 7px;
    }
  }

  a {
    color: $color-danger;
  }
}

.sidebar-menu-left,
.sidebar-menu-right {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sidebar-menu-right {
  padding-right: 10px;
}

.sidebar-menu-left {
  min-width: 200px;
  padding-left: 20px;
}

.content-padded {
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
}

.currentParagraph {
  border-left: 6px solid $color-danger;
}

.currentParagraph a {
  color: $color-danger;
  margin-left: -6px;
}

.currentPage {
  color: $color-danger;
}

// test
.knowledgebase-submenu-container {
  //background-color: white;
  .knowledgebase-submenu {
    background-color: $material-color-grey-100;
    margin: 1em;
    overflow-y: auto;
    padding: 1em 1em 1em 0.5em;
    right: 0;
    bottom: 0;
    left: 76%;

    #{headings(1,5)} a {
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.side-menu {
  .knowledgebase-submenu-container {
    .knowledgebase-submenu {
      left: 66%;
    }
  }
}
