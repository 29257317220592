



















































































































.single-page {
  margin-top: 75px;

  iframe {
    margin-bottom: 2em;
  }

  a:link {
    color: transparent;
  }

  a:link, a:hover, a:active, a:visited {
    color: #278fce;
  }

  h1, h2, h3 {
    a:link, a:active, a:visited {
      color: transparent
    }
    a:hover {
      color: #278fce;
    }
  }

  h1 {
    font-size: 1.8em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.4em;
  }

  .table th,
  .table td {
    padding: 0.4em;
    height: auto;
  }

  .center-col {

    img,
    iframe {
      width: 100%;
    }
  }

  .page-menu-left,
  .page-menu-right {
    min-height: 80vh;
    .sticky-top {
      top: 95px;
    }

    h4 {
      font-size: 1.1em;
      line-height: 150%;
      border-left: 6px solid #fff;
      padding-left: 10px;

      &.currentPage {
        a:link, a:hover, a:visited, a:active {
          color: #424242;
          text-decoration: none;
        }
        font-weight: bold;
        border-color: #424242;
      }
    }
  }
  .page-menu-right {
    background-color: #f5f5f5;
    .navbar {
      height: auto;
      .nav-link {
        padding: 0 0 0 10px;
        margin-bottom: 6px;
        border-left: 6px solid #f5f5f5;

        &.active {
          font-weight: bold;
          border-color: #424242;
          color: #424242;
        }
        &:hover {
          text-decoration: underline;
        }

      }
    }
  }

}
