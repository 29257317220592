








































































































































































































































































































































.svg-canvas svg {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;

  &.isDragging {
    /* closed hand cursor */
    cursor: url('data:image/png;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAAH/AAAB/wAAA/0AAANsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//+AB///wAf//4AH//+AD///yT/////////////////////////////8='), auto;
  }
}
.svg-canvas {
  // border: 1px solid black;
  min-height: 400px;
  height: calc(100vh - 150px);
}

@media (min-width: 768px) {
  .svg-canvas {
    height: calc(100vh - 360px);
  }
}

.svg-canvas-container {
  position: relative;
}

.use-two-fingers {
  &.show {
    display: block;
  }
  position: absolute;
  width: 100%; height: 100%;
  background-color: #777777;
  color: #ffffff;
  text-align: center;
  padding-top: 30px;
  display: none;
}
.svg-pan-zoom_viewport {
  background-color: #cccccc;
}
