










































































































































.create-account-form {
  display: flex;
  flex-flow: wrap;
}
.input-row-half {
  width: 50%;
  padding: 0.5rem 1rem;
}
.row-stick-bottom {
  width: 100%;
  flex-grow: 1;
  align-self: flex-end;
  padding: 1rem;
}
.input-row-full {
  width: 100%;
  padding: 0.5rem 1rem;
}
