






































































































































































.drawing-configuration-product-list {
  padding: 0;
  margin: 0;
  line-height: normal;
  text-indent: 0;
  list-style: none;
}
