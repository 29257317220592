<template>
  <div v-if="isLocked" class="text-center">
    <div class="alert alert-danger order-review-top-message mb-2">Deze order kan niet meer aangepast worden</div>
    <div>
      <b-button
        :class="{ 'btn-success': !canBePaid }"
        @click="startNewOrder">Maak een nieuwe order
      </b-button>
      <b-button
        :class="{ 'btn-success': canBePaid }"
        class="ml-2"
        @click="goToPayment">Verder naar betalen <i class='material-icons'>keyboard_arrow_right</i>
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrderStatusWarning',
  props: {
    canBePaid: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToPayment() {
      this.$emit('goToPayment');
    },
    startNewOrder() {
      this.$emit('startNewOrder');
    },
  },
};
</script>
<style>
    table.cart-table td {
        border: none;
    }
  .order-review-top-message {
    font-size: 1.3rem;
    color: white;
  }
</style>
