



























































































































































.project-detail-view {
  margin-top: 75px;

  .img-top {
    max-height: 600px;
  }
  // .top-img {
  //   width: 100%;
  //   max-height: 500px;
  //   width: auto;
  //   float: left;
  //   margin-right: 2rem;
  // }

  // .images-group {
  //   padding: 0;
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   justify-content: flex-start;
  //   margin-bottom: 2rem;

  //   .img-container {
  //     height: 250px;
  //     // margin-right: .5rem;
  //     // margin-top: .5rem;
  //   }

  //   .img-container img {
  //     // margin: 0;
  //     height: 100%;
  //   }
  // }

  // @media(max-width: 576px) {
  //   .top-img {
  //     margin-top: 0;
  //   }
  // }
}
