









































































































































































































































































































































































































































































































































.cart-review-view {
  .icp-details .custom-control-input:disabled ~ .custom-control-label {
    color: #000000;
  }

  .no-pointer {
    pointer-events: none;
  }

  .icp-conclusion {
    font-size: 0.875rem;
  }

  .icp-vies-details {
    padding-top: 0.5rem;
    padding-left: 2rem;
  }

  .vat-detail {
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .review-container {
    padding: 1rem;
  }

  .address-card,
  .delivery-card,
  .payment-card,
  .summary-card,
  .email-card {
    margin-bottom: 1rem;
  }

  .card-body ul.bg-dark {
    margin-left: 0;
    padding: 1rem;
    list-style: none;
  }

  .deliver-summary-list {
    list-style-type: none;
    padding: 0;
  }

  .summary-card {
    table {
      width: 100%;
    }
    tr {
      border-bottom: 1px solid rgb(230, 230, 230);
    }
    td {
      padding-top: .1rem;
      padding-bottom: .1rem;
    }
    .total-row {
      border-bottom: none;
      td {
        padding-top: 0.2rem;
      }
    }
  }

  .email-card {
    .email-buttons {
      display: flex;
      justify-content: space-between;
    }
    textarea {
      width: 100%;
      min-height: 500px;
    }
  }

  @media all and (min-width: 764px) {

    .address-card,
    .column-third,
    .summary-card,
    .email-card {
      width: calc(50% - 0.5rem);
      float: left;
    }
    .address-card {
      margin-left: 1rem;
    }
  }

  @media all and (min-width: 1200px) {

    .address-card,
    .column-third,
    .summary-card,
    .email-card {
      width: calc(33.3333% - 0.67rem);
    }

    .column-third {
      margin-left: 1rem;
    }
  }
}

