






















































.display-range .material-icons {
  font-size: .9rem;
  padding: 0;
}
