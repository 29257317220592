$color-primary: #4ba6dd !default;
$color-secondary: #424242 !default;
$color-success: rgb(112, 199, 175) !default;
$color-info:    #f5f5f5 !default;
$color-warning: rgb(255, 127, 87) !default;
$color-danger:  rgb(240, 83, 101) !default;
$color-inverse: #424242 !default;
$color-faded:   #f5f5f5 !default;
$color-snijlab: #ffe400 !default;
$color-white:   #ffffff !default;

$color-info-border: darken($color-info, 25%);

$color-text-gray: #434a54;

$media-m: 764px;
$media-l: 1200px;