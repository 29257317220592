
































































































































.drawing-configuration-price-matrix {
  width: 100%;

  td, th {
    display: none;
  }

  // always show the first 3 and the last 2.
  // they can overlap
  td:nth-child(-n+4), td:nth-last-child(-n+2),
  th:nth-child(-n+4), th:nth-last-child(-n+2) {
    display: table-cell;
  }

  .alert-info .text-muted {
    color: rgb(65, 65, 65) !important;
  }

  // @media (max-width: 576px) {
  //   table td, table th {
  //     font-size: 0.625rem;
  //   }
  // }
}
