












































































































































































.drawing-configuration-price-button {
  .popover .arrow {
    margin-left: 0;
  }

  .selectedPriority {
    background-color: #ccc;
  }
}
