






































































































.material-table-item {
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }

  img {
    max-width: 150px;
  }
}
